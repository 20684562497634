import React from 'react'

import AdminPageTitle from '../../../components/AdminPageTitle/AdminPageTitle'
import { BackwardOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { ResearchCreateFields, ResearchForm } from '../../../components/ResearchForm/ResearchForm'
import adminService from '../../../services/adminService'
import { message } from 'antd'

export const AdminResearchesCreatePage: React.FC = () => {
  const navigate = useNavigate()
  const onSaveResearch = ({ serviceId, title, body, files, images }: ResearchCreateFields, imageDescription: {[k: number]: string}) => {
    if (serviceId) {
      adminService.createResearch(title, body, +serviceId, imageDescription, files?.fileList, images?.fileList).then((data) => {
        message.success(`Исследование "${title}" успешно создано`)
        navigate('/researches')
      }).catch((data) => {
        message.error(data.message)
      })
    }
  }
  return (
    <div>
      <AdminPageTitle
        title={'Создать исследование'}
        buttonText={'Назад'}
        icon={<BackwardOutlined/>}
        onClickButton={() => navigate('/researches')}
      />
      <ResearchForm
        onSubmit={onSaveResearch}
      />
    </div>
  )
}
