import React from 'react'
import { Outlet } from 'react-router-dom'
import TopMenuComponent from '../../components/TopMenuComponent/TopMenuComponent'
import { Layout } from 'antd'
import { RootCategory } from '../../services/models'

type Props = {
    categories: RootCategory[]
}
const ClientLayout: React.FC<Props> = ({ categories }) => {
  return (
        <>
            <TopMenuComponent items={categories}/>
            <Layout.Content>
                <Layout style={{ minHeight: '500px' }}>
                    <Outlet/>
                </Layout>
            </Layout.Content>
        </>
  )
}

export default ClientLayout
