import React from 'react'
import { Typography } from 'antd'

const AdminIndexPage: React.FC = () => {
  return (
    <div>
        <Typography.Title>Админка</Typography.Title>
    </div>
  )
}

export default AdminIndexPage
