import React from 'react'
import 'antd/dist/reset.css'
import { BrowserRouter } from 'react-router-dom'
import Switcher from './routers/Switcher/Switcher'

function App () {
  return (
      <BrowserRouter>
          <Switcher />
      </BrowserRouter>
  )
}

export default App
