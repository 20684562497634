import React from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import LoginForm from '../../components/LoginForm/LoginForm'
import RegistrationForm from '../../components/RegistrationForm/RegistrationForm'
import RestoreEmailForm from '../../components/RestoreEmailForm/RestoreEmailForm'
import RestorePasswordForm from '../../components/RestorePasswordForm/RestorePasswordForm'
import { Layout, Typography, Col, Row } from 'antd'
import HeaderComponent from '../../components/HeaderComponent/HeaderComponent'

const UnauthorizedRouter: React.FC = () => {
  const navigate = useNavigate()
  return (
        <Layout>
            <HeaderComponent onHome={() => navigate('/')}/>
            <div style={{ height: '46px', backgroundColor: '#001529' }}></div>
            <Layout.Content>
                <Layout>
                    <Layout.Content>
                        <Row>
                            <Col xs={24} sm={14} md={16} style={{ padding: '20px' }}>
                                <Typography.Title level={3}>Призма - это информационно-аналитическая платформа по энергетике, нефтегазовой отрасли, сельскому хозяйству, химии и экономике.</Typography.Title>
                                <Typography.Paragraph>Мы видим свою миссию в создании российского информационно-аналитического и консультационного центра, который сможет объединить международный опыт и экспертизу, создать качественную аналитическую платформу для энергетических компаний, финансовых организаций, регуляторов и других заинтересованных организаций.</Typography.Paragraph>
                            </Col>
                            <Col xs={24} sm={10} md={8} style={{ padding: '20px', backgroundColor: 'white' }}>
                                <Routes>
                                    <Route path="/" element={<LoginForm/>}/>
                                    <Route path="/register" element={<RegistrationForm/>}/>
                                    <Route path="/restore-email" element={<RestoreEmailForm/>}/>
                                    <Route path="/restore-password" element={<RestorePasswordForm/>}/>
                                </Routes>
                            </Col>

                        </Row>
                    </Layout.Content>
                </Layout>
            </Layout.Content>
            <Layout.Footer style={{ textAlign: 'center' }}>Prisma platform ©2024</Layout.Footer>
        </Layout>
  )
}

export default UnauthorizedRouter
