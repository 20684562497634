import React from 'react'
import { RootCategory } from '../../services/models'
import { Menu } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'

type Props = {
    items: RootCategory[]
}
const TopMenuComponent: React.FC<Props> = ({ items }) => {
  const navigate = useNavigate()
  const { rootCategoryId } = useParams()

  return (
        <Menu onClick={(e) => navigate(`/catalog/${e.key}`)}
              selectedKeys={[rootCategoryId ?? '']}
              mode="horizontal"
              items={items.map((c) => ({ key: c.id.toString(), label: c.name }))}
              theme="dark"/>
  )
}

export default TopMenuComponent
