import React from 'react'
import { ResearchFile } from '../../services/models'
import FileComponent from '../FileComponent/FileComponent'

type Props = {
    files: ResearchFile[]
}

const ResearchFileListComponent: React.FC<Props> = ({ files }) => {
  if (files.length === 0) return <></>
  return (
        <ul style={{ listStyleType: 'none', padding: 0, margin: '5px 0 0 0' }}>
            {files.map((f) => (
                <li key={`file-${f.id}`}>
                    <FileComponent
                        id={f.id}
                        name={f.name}
                        mime={f.mime}/>
                </li>
            ))}
        </ul>
  )
}

export default ResearchFileListComponent
