import React from 'react'
import { Button, Form, Input, message } from 'antd'
import profileService from '../../services/profileService'

type FormValues = {
    oldPassword: string;
    newPassword: string;
    confirm: string;
}

type Props = {}

const ProfilePasswordForm: React.FC<Props> = () => {
  const [form] = Form.useForm()

  const onFinish = ({ oldPassword, newPassword }: FormValues) => {
    profileService.updatePassword(oldPassword, newPassword).then(() => {
      message.success('update password success')
      form.setFieldsValue({
        oldPassword: '',
        newPassword: '',
        confirm: ''
      })
    }).catch((e) => {
      console.log('e', e)
      if (Array.isArray(e.response.data.message)) {
        message.error(`Error: ${e.response.data.message.join(', ')}`)
      } else {
        message.error(e.response.data.message)
      }
    })
  }
  return (
        <Form
            form={form}
            layout="vertical"
            initialValues={{
              oldPassword: '',
              newPassword: '',
              confirm: ''
            }}
            onFinish={onFinish}
        >
            <Form.Item
                label="Старый"
                name="oldPassword"
                rules={[{ required: true, message: 'Пожалуйста введите ваш пароль!' }]}
            >
                <Input.Password/>
            </Form.Item>
            <Form.Item
                label="Новый пароль"
                name="newPassword"
                rules={[{ required: true, message: 'Пожалуйста введите ваш пароль!' }]}
            >
                <Input.Password/>
            </Form.Item>
            <Form.Item
                name="confirm"
                label="Подтвердите пароль"
                dependencies={['newPassword']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Пожалуйста введите ваш пароль!'
                  },
                  ({ getFieldValue }) => ({
                    validator (_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(new Error('Пароли должны совпадать'))
                    }
                  })
                ]}
            >
                <Input.Password/>
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" type="primary">Изменить</Button>
            </Form.Item>
        </Form>
  )
}

export default ProfilePasswordForm
