import React, { useEffect, useState } from 'react'
import { Button, Result, Typography } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { Research } from '../../services/models'
import userService from '../../services/userService'
import ResearchComponent from '../../components/ResearchComponent/ResearchComponent'

type Props = {}

const ResearchPage: React.FC<Props> = () => {
  const { researchId } = useParams()
  const [research, setResearch] = useState<Research | null>(null)
  const [forbidden, setForbidden] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (researchId) {
      fetchResearchById(parseInt(researchId))
    }
  }, [])

  const fetchResearchById = (id: number) => {
    setForbidden(false)
    userService.getResearch(id).then(data => {
      setResearch(data.data)
    }).catch(data => {
      if (data.response.status === 403) {
        setForbidden(true)
      }
    })
  }

  if (forbidden) {
    return (
        <Result
            status="403"
            title="Закрыто"
            subTitle="Извините, но доступ к этой странице закрыт."
            extra={<Button type="primary" onClick={() => navigate('/')}>Вернуться на главную</Button>}
        />
    )
  }

  if (!research) {
    return <></>
  }

  return (
      <ResearchComponent research={research}/>
  )
}

export default ResearchPage
