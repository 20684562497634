import React, { useEffect, useState } from 'react'
import { AttributeNumber, Layer } from '../../services/models'
import { Button, Drawer, Form, Input, message, Select, Space, Table } from 'antd'
import AdminPageTitle from '../../components/AdminPageTitle/AdminPageTitle'
import attributeNumbersService from '../../services/attributeNumbersService'
import layersService from '../../services/layersService'

type FormData = {
    name: string;
    unit: string;
    layerIds: string[];
}

type Props = {}

const AdminAttributeNumbersPage: React.FC<Props> = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [dataSource, setDataSource] = useState<AttributeNumber[]>([])
  const [layers, setLayers] = useState<Layer[]>([])

  const [createForm] = Form.useForm()
  const [showCreateForm, setShowCreateForm] = useState(false)

  const onShowCreateForm = () => {
    setShowCreateForm(true)
  }

  const closeCreateForm = () => {
    setShowCreateForm(false)
  }

  const onSubmitCreateForm = (data: FormData) => {
    setLoading(true)
    attributeNumbersService.create(data.name, (data.layerIds ?? []).map(x => +x), data.unit).then(() => {
      message.success('Успех')
      fetchData()
    }).catch((data) => {
      message.error(data.message)
    }).finally(() => {
      setLoading(false)
    })
    setShowCreateForm(false)
    createForm.resetFields()
  }

  const [editForm] = Form.useForm()
  const [editableId, setEditableId] = useState<number>(0)

  const onCloseEditForm = () => {
    setEditableId(0)
    editForm.resetFields()
  }

  const onSubmitEditForm = (data: FormData) => {
    setLoading(true)
    attributeNumbersService.update(editableId, data.name, data.layerIds.map(x => +x), data.unit).then(() => {
      message.success('Успешно!')
      fetchData()
    }).catch((data) => {
      message.error(data.message)
    }).finally(() => {
      setLoading(false)
    })
    setEditableId(0)
    editForm.resetFields()
  }

  const fetchData = () => {
    layersService.get()
      .then((response) => {
        setLayers(response.layers)
        attributeNumbersService.get()
          .then((response) => {
            setDataSource(response.attributeNumbers)
          })
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit'
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record: AttributeNumber) => (
                <Space size="middle">
                    <a onClick={() => {
                      const { id, name, layers, unit } = record
                      editForm.setFieldValue('id', id)
                      editForm.setFieldValue('name', name)
                      editForm.setFieldValue('unit', unit)
                      editForm.setFieldValue('layerIds', layers.map((l) => l.id.toString()))
                      setEditableId(id)
                    }}>Изменить</a>
                </Space>
      )
    }
  ]
  return (
        <div>
            <AdminPageTitle title={'Числа'}
                            buttonText={'Добавить'}
                            onClickButton={onShowCreateForm}/>

            <Table loading={loading} dataSource={dataSource} columns={columns} rowKey={'id'} />

            <Drawer
                title="Добавить"
                width={600}
                onClose={closeCreateForm}
                open={showCreateForm}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={closeCreateForm}>Отмена</Button>
                        <Button onClick={createForm.submit} type="primary">
                            Отправить
                        </Button>
                    </Space>
                }
            >
                <Form layout="vertical"
                      form={createForm}
                      onFinish={onSubmitCreateForm}>
                    <Form.Item label="Name"
                               name="name"
                               rules={[{ required: true }]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Unit"
                               name="unit">
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="Layers"
                        name="layerIds"
                    >
                        <Select mode="multiple">
                            {layers.map(item => (
                                <Select.Option key={item.id}>{item.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Drawer>

            <Drawer
                title="Редактирование"
                width={600}
                onClose={onCloseEditForm}
                open={!!editableId}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={onCloseEditForm}>Отмена</Button>
                        <Button onClick={editForm.submit} type="primary">
                            Отправить
                        </Button>
                    </Space>
                }
            >
                <Form layout="vertical"
                      form={editForm}
                      onFinish={onSubmitEditForm}>
                    <Form.Item label="Name"
                               name="name"
                               rules={[{ required: true }]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Unit"
                               name="unit">
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="Layers"
                        name="layerIds"
                    >
                        <Select mode="multiple">
                            {layers.map(item => (
                                <Select.Option key={item.id}>{item.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Drawer>
        </div>
  )
}

export default AdminAttributeNumbersPage
