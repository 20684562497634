import React, { useState } from 'react'
import { Permission, Research, User } from '../../services/models'
import ResearchFileListComponent from '../ResearchFileListComponent/ResearchFileListComponent'
import { Avatar, Drawer, Image, List, Tag, Typography } from 'antd'
import { API_URL } from '../../services/apiService'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../app/hooks'
import { permItems } from '../../features/perm/permSlice'

type Props = {
    items: Research[]
}
const ResearchListComponent: React.FC<Props> = ({ items }) => {
  const navigate = useNavigate()
  const [user, setUser] = useState<User>()
  const permissions = useAppSelector(permItems)
  const permissionByServiceId = new Map<number, Permission>()
  permissions.forEach((p) => {
    permissionByServiceId.set(p.serviceId, p)
  })

  const renderTitle = (item: Research) => (
        <a onClick={() => navigate(`/catalog/${item.service.category.parent.id}/${item.service.category.id}/${item.service.id}/${item.id}`)}>{item.title}</a>
  )
  const renderDescription = (item: Research) => (
        <div>
            <div>автор: <a onClick={() => setUser(item.user)}>{item.user.firstName} {item.user.lastName}</a></div>
            <ResearchFileListComponent files={item.files}/>
        </div>
  )
  const renderUser = (user: User) => (
        <>
            <Typography.Title level={4} style={{ margin: 0 }}>{user.firstName} {user.lastName}</Typography.Title>
            <Typography.Title level={5} style={{ margin: 0 }}>{user.job}</Typography.Title>
            <div style={{ padding: '10px' }}>
                <Image width={100} src={`${API_URL}/avatar/${user.id}`}/>
            </div>
            <Typography.Paragraph>{user.biography}</Typography.Paragraph>
        </>
  )
  const renderTag = (item: Research) => {
    if (!permissionByServiceId.has(item.service.id)) {
      return <Tag color="red">закрыто</Tag>
    }
    if (!item.view) {
      return <Tag color="cyan">новое</Tag>
    }
    return <></>
  }
  return (
        <>
            <List
                dataSource={items}
                renderItem={item => (
                    <List.Item key={item.id}>
                        <List.Item.Meta
                            avatar={<Avatar src={`${API_URL}/avatar/${item.user.id}`}/>}
                            title={renderTitle(item)}
                            description={renderDescription(item)}
                        />
                        <div>{renderTag(item)}</div>
                    </List.Item>
                )}
            />
            <Drawer title="Автор" placement="right" onClose={() => setUser(undefined)}
                    open={user !== undefined}>
                {user && renderUser(user)}
            </Drawer>
        </>
  )
}

export default ResearchListComponent
