import { authAPI } from './apiService'
import { Layer } from './models'

export type GetLayersResponse = {
  layers: Layer[];
}
export type GetLayerByIdResponse = {
  layer: Layer;
}

const URL = '/layers'

const layersService = {

  get: () => authAPI
    .get<GetLayersResponse>(URL)
    .then((response) => response.data),

  getById: (id: number) => authAPI
    .get<GetLayerByIdResponse>(`${URL}/${id}`)
    .then((response) => response.data),

  create: (name: string, domainId: number) => authAPI
    .post(`${URL}/create`, { name, domainId })
    .then((response) => response.data),

  update: (id: number, name: string, domainId: number) => authAPI
    .post(`${URL}/update`, { id, name, domainId })
    .then((response) => response.data)
}

export default layersService
