import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { RootCategory } from '../../services/models'

type Props = {
  categories: RootCategory[]
}
const CatalogIndexPage: React.FC<Props> = ({ categories }) => {
  const navigate = useNavigate()
  useEffect(() => {
    for (const c of categories) {
      if (c.children.length) {
        navigate(`/catalog/${c.id}/${c.children[0].id}`)
        break
      }
    }
  }, [categories])
  return <></>
}

export default CatalogIndexPage
