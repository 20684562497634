import React, { useEffect, useState } from 'react'
import userService from '../../services/userService'
import { Permission, Profile, RootCategory } from '../../services/models'
import { Checkbox, Form, message, Select } from 'antd'
import profileService from '../../services/profileService'

const schedules = [
  { label: 'Нет', value: 0 },
  { label: 'Ежедневно 8:00', value: 8 },
  { label: 'Понедельник 8:00', value: 1 },
  { label: 'Вторник 8:00', value: 2 },
  { label: 'Среда 8:00', value: 3 },
  { label: 'Четверг 8:00', value: 4 },
  { label: 'Пятница 8:00', value: 5 },
  { label: 'Суббота 8:00', value: 6 },
  { label: 'Воскресенье 8:00', value: 7 }
]

type Props = {
    profile: Profile
    permissions: Permission[]
}

const SubscriptionForm: React.FC<Props> = ({ profile, permissions }) => {
  const [form] = Form.useForm()
  const [categories, setCategories] = useState<RootCategory[] | null>(null)
  const [permissionMap, setPermissionMap] = useState<Map<number, boolean>>(new Map<number, boolean>())

  const fetchData = () => {
    userService.getCategories().then(data => setCategories(data.tree))
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    const newMap = new Map<number, boolean>()
    const subscribedServiceIds: number[] = []
    permissions.forEach(p => {
      newMap.set(p.serviceId, p.isSubscribed)
      if (p.isSubscribed) {
        subscribedServiceIds.push(p.serviceId)
      }
    })
    setPermissionMap(newMap)

    form.setFieldValue('schedule', profile.schedule)
    form.setFieldValue('subscribedServiceIds', subscribedServiceIds)
  }, [permissions, profile])

  if (!categories) {
    return <></>
  }

  const onSubmitForm = (data: {schedule: number, subscribedServiceIds: number[]}) => {
    console.log('onSubmitForm', { data })
  }

  const onChangeSchedule = (schedule: number) => {
    profileService.updateSchedule(schedule).then(() => {
      message.success('success')
    }).catch(() => {
      message.error('error')
    })
  }

  const onChangeSubscribe = (serviceId: number, checked: boolean) => {
    if (checked) {
      userService.subscribe(serviceId).then(() => {
        message.success('success')
      }).catch(() => {
        message.error('error')
      })
    } else {
      userService.unsubscribe(serviceId).then(() => {
        message.success('success')
      }).catch(() => {
        message.error('error')
      })
    }
  }

  return (
        <div>
            <Form layout="vertical"
                form={form}
                onFinish={onSubmitForm}
            >
                <Form.Item label="Расписание рассылки" name="schedule">
                    <Select options={schedules} onChange={(schedule: number) => onChangeSchedule(schedule)}/>
                </Form.Item>
                <Form.Item name="subscribedServiceIds" label="Доступные сервисы">
                    <Checkbox.Group>
                        <ul>
                            {categories.map(c => (
                                <li key={`c-${c.id}`}>
                                    {c.name}
                                    <ul>
                                        {c.children.map(cc => (
                                            <li key={`cc-${cc.id}`}>
                                                {cc.name}
                                                <ul>
                                                    {cc.services.map(s => (
                                                        <li key={`s-${s.id}`}>
                                                            <Checkbox onChange={(e) => onChangeSubscribe(e.target.value, e.target.checked)} value={s.id} disabled={!permissionMap.has(s.id)}>{s.name}</Checkbox>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </ul>
                    </Checkbox.Group>
                </Form.Item>
            </Form>
        </div>
  )
}

export default SubscriptionForm
