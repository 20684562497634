import React, { useEffect, useState } from 'react'
import { Table, Form, Input, Button, Select, Drawer, Space, message, InputNumber, Checkbox } from 'antd'
import adminService from '../../services/adminService'
import AdminPageTitle from '../../components/AdminPageTitle/AdminPageTitle'

type Option = {
    value: string;
    label: string;
}

type Data = {
    key: string;
    id: number;
    name: string;
    weight: number;
    categoryId: number;
    categoryName: string;
    isActive: boolean;
    active: string;
}

type Props = {}

const AdminServicesPage: React.FC<Props> = () => {
  const [loading, setLoading] = useState<boolean>(false)

  const [data, setData] = useState<Data[]>([])
  const [leafCategories, setLeafCategories] = useState<Option[]>([])

  const [createForm] = Form.useForm()
  const [showCreateForm, setShowCreateForm] = useState(false)

  const [editForm] = Form.useForm()
  const [editableId, setEditableId] = useState<number>(0)

  const fetchData = () => {
    setLoading(true)
    adminService.getServices().then((data) => {
      const newData: Data[] = []
      data.services.forEach((s) => {
        newData.push({
          key: s.id.toString(),
          id: s.id,
          name: s.name,
          weight: s.weight,
          categoryId: s.category.id,
          categoryName: s.category.name,
          active: s.isActive ? 'да' : 'нет',
          isActive: s.isActive
        })
      })
      setData(newData)
      adminService.getCategories().then((data) => {
        const newLeafCategories: Option[] = []
        data.categories.forEach((c) => {
          if (c.parent) {
            newLeafCategories.push({ value: c.id.toString(), label: `${c.parent.name} > ${c.name}` })
          }
        })
        setLeafCategories(newLeafCategories)
        setLoading(false)
      })
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Категория',
      dataIndex: 'categoryName',
      key: 'categoryName'
    },
    {
      title: 'Вес',
      dataIndex: 'weight',
      key: 'weight'
    },
    {
      title: 'Активный',
      dataIndex: 'active',
      key: 'active'
    },
    {
      title: 'Действия',
      key: 'actions',
      render: (_: any, record: Data) => (
                <Space size="middle">
                    <a onClick={() => {
                      const { name, id, weight, categoryId, isActive } = record
                      editForm.setFieldValue('name', name)
                      editForm.setFieldValue('weight', weight)
                      editForm.setFieldValue('isActive', isActive)
                      editForm.setFieldValue('categoryId', categoryId ? categoryId.toString() : undefined)
                      setEditableId(id)
                    }}>Изменить</a>
                </Space>
      )
    }
  ]

  const onShowCreateForm = () => {
    setShowCreateForm(true)
  }

  const closeCreateForm = () => {
    setShowCreateForm(false)
  }

  const onSubmitCreateForm = ({ name, categoryId, weight, isActive }: any) => {
    isActive = !!isActive
    if (categoryId) {
      categoryId = parseInt(categoryId)
    }
    setLoading(true)
    adminService.createService(name, categoryId, weight, isActive).then((data) => {
      message.success(`Сервис "${data.name}" успешно создан`)
      fetchData()
    }).catch((data) => {
      setLoading(false)
      message.error(data.message)
    })
    setShowCreateForm(false)
    createForm.resetFields()
  }

  const closeEditForm = () => {
    setEditableId(0)
    editForm.resetFields()
  }

  const onSubmitEditForm = ({ name, categoryId, weight, isActive }: any) => {
    isActive = !!isActive
    if (editableId === 0) return
    if (categoryId) {
      categoryId = parseInt(categoryId)
    }
    setLoading(true)
    adminService.updateService(editableId, name, categoryId, weight, isActive).then((data) => {
      message.success(`Сервис "${data.name}" успешно отредактирован`)
      fetchData()
    }).catch((data) => {
      setLoading(false)
      message.error(data.message)
    })
    setEditableId(0)
    editForm.resetFields()
  }

  return (
        <div>
            <AdminPageTitle title={'Управление сервисами'}
                            buttonText={'Новый сервис'}
                            onClickButton={onShowCreateForm}/>

            <Table loading={loading} dataSource={data} columns={columns}/>

            <Drawer
                title="Добавить новый сервис"
                width={600}
                onClose={closeCreateForm}
                open={showCreateForm}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={closeCreateForm}>Отмена</Button>
                        <Button onClick={createForm.submit} type="primary">
                            Отправить
                        </Button>
                    </Space>
                }
            >
                <Form layout="vertical"
                      hideRequiredMark
                      form={createForm}
                      onFinish={onSubmitCreateForm}>
                    <Form.Item label="Название сервиса"
                               name="name"
                               rules={[{ required: true }]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Категория"
                               name="categoryId"
                               rules={[{ required: true }]}>
                        <Select options={leafCategories}/>
                    </Form.Item>
                    <Form.Item label="Вес"
                               name="weight"
                               rules={[{ required: true }]}>
                        <InputNumber/>
                    </Form.Item>
                    <Form.Item label="Активный"
                               valuePropName="checked"
                               name="isActive">
                        <Checkbox />
                    </Form.Item>
                </Form>
            </Drawer>

            <Drawer
                title="Редактировать сервис"
                width={600}
                onClose={closeEditForm}
                open={!!editableId}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={closeEditForm}>Отмена</Button>
                        <Button onClick={editForm.submit} type="primary">
                            Отправить
                        </Button>
                    </Space>
                }
            >
                <Form layout="vertical"
                      hideRequiredMark
                      form={editForm}
                      onFinish={onSubmitEditForm}>
                    <Form.Item label="Название сервиса"
                               name="name"
                               rules={[{ required: true }]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Категория"
                               name="categoryId">
                        <Select options={leafCategories}/>
                    </Form.Item>
                    <Form.Item label="Вес"
                               name="weight"
                               rules={[{ required: true }]}>
                        <InputNumber/>
                    </Form.Item>
                    <Form.Item label="Активный"
                               valuePropName="checked"
                               name="isActive">
                        <Checkbox />
                    </Form.Item>
                </Form>
            </Drawer>
        </div>
  )
}

export default AdminServicesPage
