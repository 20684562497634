import React from 'react'
import { RootCategory } from '../../services/models'
import { Breadcrumb } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'

type Props = {
    categories: RootCategory[]
}

const BreadcrumbComponent: React.FC<Props> = ({ categories }) => {
  const navigate = useNavigate()
  const { rootCategoryId, subCategoryId, serviceId, researchId } = useParams()

  const getBreadcrumbItems = (): { title: string | React.ReactNode }[] => {
    const rc = categories.find((i) => i.id.toString() === rootCategoryId)

    const items: { title: string | React.ReactNode }[] = []
    if (!rootCategoryId || !rc) {
      items.push({ title: 'Главная' })
      return items
    }

    items.push({ title: <a onClick={() => navigate('/')}>Главная</a> })

    const sc = rc.children.find((i) => i.id.toString() === subCategoryId)

    if (!subCategoryId || !sc) {
      items.push({ title: rc.name })
      return items
    }

    items.push({
      title: <a onClick={() => navigate(`/catalog/${rc.id}`)}>{rc.name}</a>
    })

    const s = sc.services.find((i) => i.id.toString() === serviceId)

    if (!serviceId || !s) {
      items.push({ title: sc.name })
      return items
    }

    items.push({
      title: <a onClick={() => navigate(`/catalog/${rc.id}/${sc.id}`)}>{sc.name}</a>
    })

    if (!researchId) {
      items.push({ title: s.name })
      return items
    }

    items.push({
      title: <a onClick={() => navigate(`/catalog/${rc.id}/${sc.id}/${s.id}`)}>{s.name}</a>
    })

    return items
  }

  return (
        <Breadcrumb items={getBreadcrumbItems()} style={{ margin: '10px' }}/>
  )
}
export default BreadcrumbComponent
