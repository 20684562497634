import React, { useEffect, useState } from 'react'
import { AttributeDictionary, Layer } from '../../services/models'
import { Button, Drawer, Form, Input, message, Select, Space, Table } from 'antd'
import AdminPageTitle from '../../components/AdminPageTitle/AdminPageTitle'
import layersService from '../../services/layersService'
import attributeDictionariesService from '../../services/attributeDictionariesService'
import { useNavigate } from 'react-router-dom'

type Props = {}

const AdminAttributeDictionaryPage: React.FC<Props> = () => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState<boolean>(false)
  const [dataSource, setDataSource] = useState<AttributeDictionary[]>([])
  const [layers, setLayers] = useState<Layer[]>([])

  const [createForm] = Form.useForm()
  const [showCreateForm, setShowCreateForm] = useState(false)

  const onShowCreateForm = () => {
    setShowCreateForm(true)
  }

  const closeCreateForm = () => {
    setShowCreateForm(false)
  }

  const onSubmitCreateForm = (data: { name:string, layerIds: string[]}) => {
    setLoading(true)
    attributeDictionariesService.create(data.name, (data.layerIds ?? []).map(x => +x)).then((data) => {
      message.success('Успех')
      fetchData()
    }).catch((data) => {
      message.error(data.message)
    }).finally(() => {
      setLoading(false)
    })
    setShowCreateForm(false)
    createForm.resetFields()
  }

  const [editForm] = Form.useForm()
  const [editableId, setEditableId] = useState<number>(0)

  const onCloseEditForm = () => {
    setEditableId(0)
    editForm.resetFields()
  }

  const onSubmitEditForm = (data: { name:string, layerIds: string[]}) => {
    setLoading(true)
    attributeDictionariesService.update(editableId, data.name, data.layerIds.map(x => +x)).then((data) => {
      message.success('Успешно!')
      fetchData()
    }).catch((data) => {
      message.error(data.message)
    }).finally(() => {
      setLoading(false)
    })
    setEditableId(0)
    editForm.resetFields()
  }

  const fetchData = () => {
    layersService.get()
      .then((response) => {
        setLayers(response.layers)
        attributeDictionariesService.get()
          .then((response) => {
            setDataSource(response.attributeDictionaries)
          })
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Действия',
      key: 'actions',
      render: (_: any, record: AttributeDictionary) => (
          <Space size="middle">
              <a onClick={() => {
                const { id, name, layers } = record
                editForm.setFieldValue('id', id)
                editForm.setFieldValue('name', name)
                editForm.setFieldValue('layerIds', layers.map((l) => l.id.toString()))
                setEditableId(id)
              }}>Изменить</a>
              <a onClick={() => {
                const { id } = record
                navigate(`/attribute-dictionary-values/${id}`)
              }}>Список значений</a>
          </Space>
      )
    }
  ]
  return (
        <div>
            <AdminPageTitle title={'Словари'}
                            buttonText={'Добавить'}
                            onClickButton={onShowCreateForm}/>

            <Table loading={loading} dataSource={dataSource} columns={columns} rowKey={'id'}/>

            <Drawer
                title="Добавить"
                width={600}
                onClose={closeCreateForm}
                open={showCreateForm}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={closeCreateForm}>Отмена</Button>
                        <Button onClick={createForm.submit} type="primary">
                            Отправить
                        </Button>
                    </Space>
                }
            >
                <Form layout="vertical"
                      form={createForm}
                      onFinish={onSubmitCreateForm}>
                    <Form.Item label="Name"
                               name="name"
                               rules={[{ required: true }]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="Layers"
                        name="layerIds"
                    >
                        <Select mode="multiple">
                            {layers.map(item => (
                                <Select.Option key={item.id}>{item.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Drawer>

            <Drawer
                title="Редактирование"
                width={600}
                onClose={onCloseEditForm}
                open={!!editableId}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={onCloseEditForm}>Отмена</Button>
                        <Button onClick={editForm.submit} type="primary">
                            Отправить
                        </Button>
                    </Space>
                }
            >
                <Form layout="vertical"
                      form={editForm}
                      onFinish={onSubmitEditForm}>
                    <Form.Item label="Name"
                               name="name"
                               rules={[{ required: true }]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="Layers"
                        name="layerIds"
                    >
                        <Select mode="multiple">
                            {layers.map(item => (
                                <Select.Option key={item.id}>{item.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Drawer>
        </div>
  )
}

export default AdminAttributeDictionaryPage
