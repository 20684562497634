import React, { useState } from 'react'
import { Typography, Drawer, Image, Row, Col, Button } from 'antd'
import ReactQuill from 'react-quill'
import { Research } from '../../services/models'
import dayjs from 'dayjs'
import { API_URL } from '../../services/apiService'
import ResearchFileListComponent from '../ResearchFileListComponent/ResearchFileListComponent'
import { ZoomOutOutlined, ZoomInOutlined } from '@ant-design/icons'

type Props = {
    research: Research;
}

const ResearchComponent: React.FC<Props> = ({ research }) => {
  const { body, files, createdAt, user } = research
  const [open, setOpen] = useState(false)

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }
  return (
        <div style={{ display: 'flex' }}>
            <div style={{ flexGrow: 1, backgroundColor: 'white', padding: '10px', marginRight: '10px' }}>
                <Typography.Title level={3}>{research.title}</Typography.Title>
                <Typography.Text>Дата: {dayjs(createdAt).format('DD/MM/YYYY')}</Typography.Text>
                <br/>
                <Typography.Text>Автор: <Typography.Link
                    onClick={showDrawer}>{user.firstName} {user.lastName}</Typography.Link></Typography.Text>
                {!!files.length && <ResearchFileListComponent files={files}/>}
                <ReactQuill
                    value={body}
                    readOnly={true}
                    theme="bubble"
                />
            </div>

            <div style={{ width: '250px', minWidth: '250px' }}>
                <Image.PreviewGroup
                    preview={{
                      countRender: (current, total) => {
                        const image = research.images[current - 1]
                        return (
                                <div style={{ textAlign: 'center', backgroundColor: 'rgba(0,0,0,0.2)', borderRadius: '6px', padding: '10px' }}>
                                     <Typography.Text
                                        style={{ color: 'white' }}>{current} / {total} {image && research.imageDescription[image.id] ? ` - ${research.imageDescription[image.id]}` : ''}</Typography.Text>
                                </div>
                        )
                      },
                      toolbarRender: (on, info) => {
                        return (
                            <div style={{
                              textAlign: 'center',
                              backgroundColor: 'rgba(0,0,0,0.2)',
                              borderRadius: '6px'
                            }}>
                                <Button type="text" onClick={info.actions.onZoomOut}
                                        icon={<ZoomOutOutlined style={{ color: 'white' }}/>}/>
                                <Button type="text" onClick={info.actions.onZoomIn}
                                        icon={<ZoomInOutlined style={{ color: 'white' }}/>}/>
                            </div>
                        )
                      }
                    }}>
                    {research.images.map((image, i) => (
                        <div key={`image-${i}`} style={{ textAlign: 'left', padding: '10px', backgroundColor: 'white', marginBottom: '10px' }}>
                            <Image style={{ width: '100%' }} src={`${API_URL}/${image.path}`}/>
                            <Typography.Text>{research.imageDescription[image.id]}</Typography.Text>
                        </div>
                    ))}
                </Image.PreviewGroup>
            </div>

            <Drawer title="Автор" placement="right" onClose={onClose} open={open}>
                <Typography.Title level={4} style={{ margin: 0 }}>{user.firstName} {user.lastName}</Typography.Title>
                <Typography.Title level={5} style={{ margin: 0 }}>{user.job}</Typography.Title>
                <div style={{ padding: '10px' }}>
                    <Image width={100} src={`${API_URL}/avatar/${user.id}`}/>
                </div>
                <Typography.Paragraph>{user.biography}</Typography.Paragraph>
            </Drawer>
        </div>
  )
}

export default ResearchComponent
