import React from 'react'
import { Button, Col, Row, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

type Props = {
    title: string;
    buttonText?: string;
    icon?: React.ReactElement
    onClickButton?: () => void;
}

const AdminPageTitle: React.FC<Props> = ({ title, buttonText, onClickButton, icon }) => (
    <Row justify="space-between" align="middle" style={{ padding: '15px 0' }}>
        <Col>
            <Typography.Title level={3} style={{ margin: 0 }}>{title}</Typography.Title>
        </Col>
        {buttonText && onClickButton && (
            <Col>
                <Button type="primary" onClick={onClickButton} icon={icon || <PlusOutlined/>}>{buttonText}</Button>
            </Col>
        )}
    </Row>
)

export default AdminPageTitle
