import React from 'react'
import { message, Typography } from 'antd'
import userService from '../../services/userService'
import MimeIconComponent from '../MimeIconComponent/MimeIconComponent'

type Props = {
    id: number
    name: string
    mime: string
}
const FileComponent: React.FC<Props> = ({ id, name, mime }) => {
  return <Typography.Text style={{ cursor: 'pointer' }} onClick={() => {
    userService.downloadFile(id, name).catch((data) => {
      message.error('Не удалось скачать')
    })
  }}><MimeIconComponent mime={mime}/> {name}</Typography.Text>
}

export default FileComponent
