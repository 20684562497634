import React, { useEffect, useState } from 'react'
import { Layer, Obj, ObjValueDictionary, ObjValueNumber } from '../../services/models'
import { Button, Drawer, Form, Input, message, Select, Space, Table, InputNumber } from 'antd'
import AdminPageTitle from '../../components/AdminPageTitle/AdminPageTitle'
import objsService from '../../services/objsService'
import { useParams } from 'react-router-dom'

type FormData = {
    name: string;
    description: string;
    src: string;
    lat: string;
    long: string;
    attributeNumbers: { [id: string]: { value: string } };
    attributeDictionaries: { [id: string]: { valueId: string } };
}

type Props = {}

const AdminObjsPage: React.FC<Props> = () => {
  const { layerId } = useParams()
  if (!layerId) return <></>

  const [loading, setLoading] = useState<boolean>(false)

  const [layer, setLayer] = useState<Layer>()
  const [dataSource, setDataSource] = useState<Obj[]>([])
  const [columns, setColumns] = useState<any[]>([])

  const [createForm] = Form.useForm()
  const [editForm] = Form.useForm()

  const [editableId, setEditableId] = useState<number>(0)
  const [showCreateForm, setShowCreateForm] = useState(false)

  const onShowCreateForm = () => {
    setShowCreateForm(true)
  }
  const closeCreateForm = () => {
    setShowCreateForm(false)
  }

  const onSubmitCreateForm = (data: FormData) => {
    setLoading(true)
    const objValueNumbers: ObjValueNumber[] = []
    if (data.attributeNumbers) {
      for (const [k, v] of Object.entries(data.attributeNumbers)) {
        objValueNumbers.push({ attributeNumberId: +k, value: v.value })
      }
    }

    const objValueDictionaries: ObjValueDictionary[] = []
    if (data.attributeDictionaries) {
      for (const [k, v] of Object.entries(data.attributeDictionaries)) {
        objValueDictionaries.push({ attributeDictionaryId: +k, valueId: +v.valueId })
      }
    }

    objsService.create(data.name, +layerId, +data.long, +data.lat, objValueNumbers, objValueDictionaries, data.src, data.description).then(() => {
      message.success('Успех')
      fetchData()
    }).catch((data) => {
      message.error(data.message)
    }).finally(() => {
      setLoading(false)
    })
    setShowCreateForm(false)
    createForm.resetFields()
  }

  const onCloseEditForm = () => {
    setEditableId(0)
    editForm.resetFields()
  }

  const onSubmitEditForm = (data: FormData) => {
    setLoading(true)
    const objValueNumbers: ObjValueNumber[] = []
    if (data.attributeNumbers) {
      for (const [k, v] of Object.entries(data.attributeNumbers)) {
        objValueNumbers.push({ attributeNumberId: +k, value: v.value })
      }
    }

    const objValueDictionaries: ObjValueDictionary[] = []
    if (data.attributeDictionaries) {
      for (const [k, v] of Object.entries(data.attributeDictionaries)) {
        objValueDictionaries.push({ attributeDictionaryId: +k, valueId: +v.valueId })
      }
    }

    objsService.update(editableId, data.name, +layerId, +data.long, +data.lat, objValueNumbers, objValueDictionaries, data.src, data.description).then(() => {
      message.success('Успешно!')
      fetchData()
    }).catch((data) => {
      message.error(data.message)
    }).finally(() => {
      setLoading(false)
    })
    setEditableId(0)
    editForm.resetFields()
  }

  const fetchData = () => {
    if (!layerId) return
    objsService.get(+layerId)
      .then((r) => {
        setLayer(r.layer)
        setDataSource(r.objs)
        const attributes: any[] = []
        for (const a of r.layer.attributeNumbers) {
          attributes.push({
            title: a.unit ? `${a.name} (${a.unit})` : a.name,
            key: `number-${a.id}`,
            render: (_: any, o: Obj) => {
              const numberValueByAttributeId: Record<number, number> = {}
              o.numberValues?.forEach((i) => {
                if (i.value) {
                  numberValueByAttributeId[i.attributeNumberId] = parseFloat(i.value)
                }
              })
              return <div>{numberValueByAttributeId[a.id]}</div>
            }
          })
        }
        for (const a of r.layer.attributeDictionaries) {
          const nameById: Record<number, string> = {}
          for (const v of a.values) {
            nameById[v.id] = v.name
          }

          attributes.push({
            title: a.name,
            key: `dictionary-${a.id}`,
            render: (_: any, o: Obj) => {
              const dictionaryValueIdByAttributeId: Record<number, string> = {}
              o.dictionaryValues?.forEach((i) => {
                dictionaryValueIdByAttributeId[i.attributeDictionaryId] = nameById[i.valueId]
              })

              return <div>{dictionaryValueIdByAttributeId[a.id]}</div>
            }
          })
        }

        setColumns([
          {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
          },
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
          },
          ...attributes,
          {
            title: 'Действия',
            key: 'actions',
            render: (_: any, record: Obj) => (
                      <Space size="middle">
                          <a onClick={() => {
                            const { id, name, lat, long, numberValues, dictionaryValues, description, src } = record
                            editForm.setFieldValue('id', id)
                            editForm.setFieldValue('name', name)
                            editForm.setFieldValue('src', src)
                            editForm.setFieldValue('description', description)
                            editForm.setFieldValue('lat', lat)
                            editForm.setFieldValue('long', long)

                            const numberValueByAttributeId: Record<number, number> = {}
                            numberValues?.forEach((i) => {
                              if (i.value) {
                                numberValueByAttributeId[i.attributeNumberId] = parseFloat(i.value)
                              }
                            })

                            const dictionaryValueIdByAttributeId: Record<number, string> = {}
                            dictionaryValues?.forEach((i) => {
                              dictionaryValueIdByAttributeId[i.attributeDictionaryId] = i.valueId.toString()
                            })

                            r.layer.attributeNumbers.forEach((a) => {
                              const value = numberValueByAttributeId[a.id]
                              if (value) {
                                editForm.setFieldValue(['attributeNumbers', a.id.toString(), 'value'], value)
                              }
                            })

                            r.layer.attributeDictionaries.forEach((a) => {
                              const valueId = dictionaryValueIdByAttributeId[a.id]
                              editForm.setFieldValue(['attributeDictionaries', a.id.toString(), 'valueId'], valueId)
                              editForm.setFieldValue('test', valueId)
                            })

                            setEditableId(id)
                          }}>Изменить</a>
                      </Space>
            )
          }
        ])
      })
  }

  useEffect(() => {
    fetchData()
  }, [layerId])

  const renderFormItems = () => {
    if (!layer) return
    return <>
          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input/>
          </Form.Item>

          <Form.Item label="Description" name="description">
              <Input.TextArea rows={4} maxLength={5000} />
          </Form.Item>

          <Form.Item label="src" name="src">
              <Input/>
          </Form.Item>

          <Form.Item label="Lat"
                     name="lat">
              <InputNumber<string>
                  min="-90"
                  max="90"
                  step="0.000001"
                  stringMode
              />
          </Form.Item>
          <Form.Item label="Long" name="long">
              <InputNumber<string> min="-180" max="180" step="0.000001" stringMode/>
          </Form.Item>

        <div>
            {
                layer.attributeNumbers.map(a => (
                    <Form.Item key={a.id.toString()}
                               label={a.unit ? a.name + '(' + a.unit + ')' : a.name}
                               name={['attributeNumbers', a.id.toString(), 'value']}>
                        <InputNumber
                            style={{ width: 150 }}
                        />
                    </Form.Item>
                ))
            }
        </div>

        <div>
            {
                layer.attributeDictionaries.map(a => (
                    <Form.Item key={a.id.toString()}
                               label={a.name}
                               name={['attributeDictionaries', a.id.toString(), 'valueId']}>
                        <Select options={a.values.map(v => ({ value: v.id.toString(), label: v.name }))}/>
                    </Form.Item>
                ))
            }
        </div>

      </>
  }

  return (
        <div>
            <AdminPageTitle title={layer?.name!}
                            buttonText={'Добавить'}
                            onClickButton={onShowCreateForm}/>
            <div style={{
              background: 'white',
              overflow: 'scroll'
            }}>
                <Table loading={loading} dataSource={dataSource} columns={columns} rowKey={'id'} tableLayout={'auto'}/>

            </div>

            <Drawer
                title="Добавить"
                width={600}
                onClose={closeCreateForm}
                open={showCreateForm}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={closeCreateForm}>Отмена</Button>
                        <Button onClick={createForm.submit} type="primary">
                            Отправить
                        </Button>
                    </Space>
                }
            >
                <Form layout="vertical"
                      form={createForm}
                      onFinish={onSubmitCreateForm}>
                    {renderFormItems()}
                </Form>
            </Drawer>

            <Drawer
                title="Редактирование"
                width={600}
                onClose={onCloseEditForm}
                open={!!editableId}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={onCloseEditForm}>Отмена</Button>
                        <Button onClick={editForm.submit} type="primary">
                            Отправить
                        </Button>
                    </Space>
                }
            >
                <Form layout="vertical"
                      form={editForm}
                      onFinish={onSubmitEditForm}>
                    {renderFormItems()}
                </Form>
            </Drawer>
        </div>
  )
}

export default AdminObjsPage
