import { authAPI } from './apiService'
import { AttributeNumber } from './models'

export type GetAttributeNumbersResponse = {
    attributeNumbers: AttributeNumber[];
}

const URL = '/attribute-numbers'

const attributeNumbersService = {

  get: () => authAPI
    .get<GetAttributeNumbersResponse>(URL)
    .then((response) => response.data),

  create: (name: string, layerIds: number[], unit: string) => authAPI
    .post(`${URL}/create`, { name, layerIds, unit })
    .then((response) => response.data),

  update: (id: number, name: string, layerIds: number[], unit: string) => authAPI
    .post(`${URL}/update`, { id, name, layerIds, unit })
    .then((response) => response.data)

}

export default attributeNumbersService
