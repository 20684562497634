import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import adminService from '../../../services/adminService'
import { Typography } from 'antd'
import { Research } from '../../../services/models'
import ResearchComponent from '../../../components/ResearchComponent/ResearchComponent'

export const AdminResearchesViewPage: React.FC = () => {
  const { researchId } = useParams()
  const [research, setResearch] = useState<Research>()

  const fetchData = () => {
    if (!researchId) {
      return null
    }
    adminService.getResearchById(+researchId).then(data => {
      setResearch(data.data)
    })
  }
  useEffect(() => {
    fetchData()
  }, [])

  if (!research) {
    return (
      <div>Loading...</div>
    )
  }

  return (
      <ResearchComponent research={research}/>
  )
}
