import React, { useEffect, useState } from 'react'
import AdminPageTitle from '../../../components/AdminPageTitle/AdminPageTitle'
import { BackwardOutlined } from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom'
import { ResearchCreateFields, ResearchForm } from '../../../components/ResearchForm/ResearchForm'
import adminService from '../../../services/adminService'
import { message } from 'antd'
import { Research } from '../../../services/models'

export const AdminResearchesEditPage: React.FC = () => {
  const navigate = useNavigate()
  const { researchId } = useParams()
  const [research, setResearch] = useState<Research>()

  const fetchData = () => {
    if (!researchId) {
      return null
    }
    adminService.getResearchById(+researchId).then(data => {
      setResearch(data.data)
    })
  }
  useEffect(() => {
    fetchData()
  }, [])

  const onUpdateResearch = ({ serviceId, userId, title, body }: ResearchCreateFields, imageDescription: {[k: number]: string}) => {
    if (serviceId && research) {
      adminService.updateResearch(research.id, title, body, +serviceId, +userId, imageDescription).then((data) => {
        message.success(`Исследование "${title}" успешно обновлено`)
        navigate('/researches')
      }).catch((data) => {
        message.error(data.message)
      })
    }
  }
  const onDelete = () => {
    if (research) {
      adminService.removeResearch(research.id).then((data) => {
        if (data) {
          message.success('Исследование успешно удалено')
          return navigate('/researches')
        }
        message.error('Ошибка удаления исследования')
        return navigate('/researches')
      })
    } else {
      message.error('Ошибка удаления исследования')
    }
  }

  return (
    <div>
      <AdminPageTitle
        title={'Обновить исследование'}
        buttonText={'Назад'}
        icon={<BackwardOutlined/>}
        onClickButton={() => navigate('/researches')}
      />
      <ResearchForm
        data={research}
        onSubmit={onUpdateResearch}
        onDelete={onDelete}
      />
    </div>
  )
}
