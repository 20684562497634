import { authAPI } from './apiService'
import { AttributeDictionary } from './models'

export type GetAttributeDictionariesResponse = {
    attributeDictionaries: AttributeDictionary[];
}

const URL = '/attribute-dictionaries'

const attributeDictionariesService = {

  get: () => authAPI
    .get<GetAttributeDictionariesResponse>(URL)
    .then((response) => response.data),

  create: (name: string, layerIds: number[]) => authAPI
    .post(`${URL}/create`, { name, layerIds })
    .then((response) => response.data),

  update: (id: number, name: string, layerIds: number[]) => authAPI
    .post(`${URL}/update`, { id, name, layerIds })
    .then((response) => response.data)

}

export default attributeDictionariesService
