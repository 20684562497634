import React from 'react'
import {
  FileZipOutlined,
  FileExcelOutlined,
  FileJpgOutlined,
  FileOutlined,
  FilePdfOutlined,
  FileWordOutlined
} from '@ant-design/icons'

type Props = {
    mime: string
}

const MimeIconComponent: React.FC<Props> = ({ mime }) => {
  switch (mime) {
    case 'application/pdf':
      return <FilePdfOutlined style={{ color: 'red' }}/>
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return <FileWordOutlined style={{ color: 'blue' }} />
    case 'application/msexcel':
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return <FileExcelOutlined style={{ color: 'green' }} />
    case 'image/jpeg':
      return <FileJpgOutlined style={{ color: 'orange' }} />
    case 'application/vnd.rar':
    case 'application/x-rar-compressed':
    case 'application/octet-stream':
    case 'application/zip':
    case 'application/x-zip-compressed':
    case 'multipart/x-zip':
      return <FileZipOutlined style={{ color: 'purple' }} />
  }
  return <FileOutlined/>
}

export default MimeIconComponent
