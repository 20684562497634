import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Profile, Research, RootCategory, Service } from '../../services/models'
import userService from '../../services/userService'
import ResearchListComponent from '../../components/ResearchListComponent/ResearchListComponent'
import { Button, Col, Result, Row, Typography } from 'antd'
import { BellFilled, BellOutlined } from '@ant-design/icons'
import { useAppSelector } from '../../app/hooks'
import { permItems, subscribe, unsubscribe } from '../../features/perm/permSlice'
import { useDispatch } from 'react-redux'
import ResearchComponent from '../../components/ResearchComponent/ResearchComponent'

type Props = {
  categories: RootCategory[];
}

const ServicePage: React.FC<Props> = ({ categories }) => {
  const dispatch = useDispatch()
  const perms = useAppSelector(permItems)
  const navigate = useNavigate()

  const { rootCategoryId, subCategoryId, serviceId } = useParams()
  const [researches, setResearches] = useState<Research[]>([])
  const [service, setService] = useState<Service>()
  const [forbidden, setForbidden] = useState<boolean>(false)

  useEffect(() => {
    if (categories && rootCategoryId && subCategoryId && serviceId) {
      const rc = categories.find((i) => i.id.toString() === rootCategoryId)
      if (rc) {
        const sc = rc.children.find((i) => i.id.toString() === subCategoryId)
        if (sc) {
          const s = sc.services.find((i) => i.id.toString() === serviceId)
          if (s) {
            setService(s)
          }
        }
      }
    }
  }, [categories, rootCategoryId, subCategoryId, serviceId])

  useEffect(() => {
    if (serviceId) {
      fetchResearchesByServerId(parseInt(serviceId))
    }
  }, [])

  const fetchResearchesByServerId = (serverId: number) => {
    setForbidden(false)
    userService.getResearchesByServiceId(serverId).then(data => {
      setResearches(data.data)
    }).catch(data => {
      if (data.response.status === 403) {
        setForbidden(true)
      }
    })
  }

  const renderFeed = () => {
    if (!serviceId) return
    const id = parseInt(serviceId)
    const p = perms.find((i) => i.serviceId === id)
    if (!p) return <></>
    return <Button onClick={() => {
      if (p.isSubscribed) {
        dispatch(unsubscribe(id))
        userService.unsubscribe(id)
      } else {
        dispatch(subscribe(id))
        userService.subscribe(id)
      }
    }} shape="circle" icon={p.isSubscribed ? <BellFilled /> : <BellOutlined />}/>
  }

  if (forbidden) {
    return (
        <Result
            status="403"
            title="Закрыто"
            subTitle="Извините, но доступ к этой странице закрыт."
            extra={<Button type="primary" onClick={() => navigate('/')}>Вернуться на главную</Button>}
        />
    )
  }

  if (!service) {
    return <></>
  }

  return (
      <div style={{ backgroundColor: 'white', padding: '10px' }}>
        <Row justify={'space-between'}>
          <Col><Typography.Title level={3}>{service.name}</Typography.Title></Col>
          <Col>{renderFeed()}</Col>
        </Row>
        <ResearchListComponent items={researches}/>
      </div>
  )
}

export default ServicePage
