import { authAPI } from './apiService'
import { Obj, ObjValueDictionary, ObjValueNumber, Layer } from './models'

export type GetObjsResponse = {
  objs: Obj[];
  layer: Layer;
}

const URL = '/objs'

const objsService = {

  get: (layerId: number) => authAPI
    .get<GetObjsResponse>(`${URL}?layerId=${layerId}`)
    .then((response) => response.data),

  create: (
    name: string,
    layerId: number,
    long: number,
    lat: number,
    numbers: ObjValueNumber[],
    dictionaries: ObjValueDictionary[],
    src: string,
    description: string
  ) => authAPI
    .post(`${URL}/create`, { name, layerId, long, lat, numbers, dictionaries, src, description })
    .then((response) => response.data),

  update: (
    id: number,
    name: string,
    layerId: number,
    long: number,
    lat: number,
    numbers: ObjValueNumber[],
    dictionaries: ObjValueDictionary[],
    src: string,
    description: string
  ) => authAPI
    .post(`${URL}/update`, { id, name, layerId, long, lat, numbers, dictionaries, src, description })
    .then((response) => response.data)
}

export default objsService
