import { useAppDispatch, useAppSelector } from '../app/hooks'
import authService, { RegisterFormValues } from '../services/authService'
import { authLoading, authUser, setLoading, setProfile } from '../features/auth/authSlice'
import tokenService from '../services/tokenService'
import profileService from '../services/profileService'

const useAuth = () => {
  const loading = useAppSelector(authLoading)
  const profile = useAppSelector(authUser)
  const dispatch = useAppDispatch()

  const fetchProfile = () => {
    dispatch(setLoading(true))
    profileService.profile()
      .then((data) => {
        dispatch(setProfile(data.data))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }

  const login = (email: string, password: string) => authService.login(email, password)
    .then((jwtResponse) => {
      if (jwtResponse && jwtResponse.accessToken) {
        tokenService.set(jwtResponse.accessToken)
        fetchProfile()
      }
      return jwtResponse
    })

  const register = (values: RegisterFormValues) => authService.register(values)
    .then((jwtResponse) => {
      if (jwtResponse && jwtResponse.accessToken) {
        tokenService.set(jwtResponse.accessToken)
        fetchProfile()
      }
      return jwtResponse
    })

  const logout = () => {
    authService.logout()
    // @ts-ignore
    window.location = '/'
  }

  const restoreLink = (email: string) => authService.restoreLink(email)

  const restorePassword = (email: string, hash: string, password: string) => authService.restorePassword(email, hash, password)

  return {
    register,
    login,
    logout,
    fetchProfile,
    loading,
    profile,
    restoreLink,
    restorePassword
  }
}

export default useAuth
