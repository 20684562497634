import { authAPI } from './apiService'
import { Parser, Research, RootCategory } from './models'
import FileDownload from 'js-file-download'

export type GetResearchResponse = {
    data: Research;
}

export type GetResearchesByServiceIdResponse = {
    data: Research[];
}

export type SearchResponse = {
    data: Research[];
}

export type FeedResponse = {
    data: Research[];
}

export type GetCategoriesResponse = {
    tree: RootCategory[];
}

export type GetParsersResponse = {
    list: Parser[];
}

const userService = {
  getResearch: (id: number) => authAPI.get<GetResearchResponse>(`/researches/get/${id}`)
    .then((response) => response.data),

  getResearchesByServiceId: (serviceId: number) => authAPI.get<GetResearchesByServiceIdResponse>(`/researches/list/${serviceId}`)
    .then((response) => response.data),

  search: (query: string) => authAPI.get<SearchResponse>('/researches/search', { params: { query } })
    .then((response) => response.data),

  getCategories: () => authAPI.get<GetCategoriesResponse>('/categories/tree')
    .then((response) => response.data),

  downloadFile: (id: number, name: string) => authAPI.get(`/research-files/${id}`, { responseType: 'blob' })
    .then((response) => FileDownload(response.data, name)),

  parsersList: () => authAPI.get<GetParsersResponse>('/parser')
    .then((response) => response.data),

  parserDownloadFile: (dir: string, file: string, name: string) => authAPI.get(`/parser/${dir}/${file}`, { responseType: 'blob' })
    .then((response) => FileDownload(response.data, name)),

  feed: () => authAPI.get<FeedResponse>('/researches/feed')
    .then((response) => response.data),

  subscribe: (serviceId: number) => authAPI.post(`/permissions/service/${serviceId}/subscribe`)
    .then((response) => response.data),

  unsubscribe: (serviceId: number) => authAPI.post(`/permissions/service/${serviceId}/unsubscribe`)
    .then((response) => response.data)

}

export default userService
