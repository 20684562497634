import React, { useEffect, useState } from 'react'
import { AttributeDictionaryValue, AttributeDictionary } from '../../services/models'
import { Button, Drawer, Form, Input, message, Select, Space, Table } from 'antd'
import AdminPageTitle from '../../components/AdminPageTitle/AdminPageTitle'
import { useParams } from 'react-router-dom'
import attributeDictionaryValuesService from '../../services/attributeDictionaryValuesService'

type Props = {}

const AdminAttributeDictionaryValuesPage: React.FC<Props> = () => {
  const { attributeDictionaryId } = useParams()
  if (attributeDictionaryId == null) {
    return <></>
  }

  const [loading, setLoading] = useState<boolean>(false)
  const [dataSource, setDataSource] = useState<AttributeDictionaryValue[]>([])
  const [attributeDictionary, setAttributeDictionary] = useState<AttributeDictionary>()

  const [createForm] = Form.useForm()
  const [showCreateForm, setShowCreateForm] = useState(false)

  const onShowCreateForm = () => {
    setShowCreateForm(true)
  }

  const closeCreateForm = () => {
    setShowCreateForm(false)
  }

  const onSubmitCreateForm = (data: { name:string}) => {
    setLoading(true)
    attributeDictionaryValuesService.create(+attributeDictionaryId, data.name).then((data) => {
      message.success('Успех')
      fetchData()
    }).catch((data) => {
      message.error(data.message)
    }).finally(() => {
      setLoading(false)
    })
    setShowCreateForm(false)
    createForm.resetFields()
  }

  const [editForm] = Form.useForm()
  const [editableId, setEditableId] = useState<number>(0)

  const onCloseEditForm = () => {
    setEditableId(0)
    editForm.resetFields()
  }

  const onSubmitEditForm = (data: { name:string }) => {
    setLoading(true)
    attributeDictionaryValuesService.update(+attributeDictionaryId, editableId, data.name).then((data) => {
      message.success('Успешно!')
      fetchData()
    }).catch((data) => {
      message.error(data.message)
    }).finally(() => {
      setLoading(false)
    })
    setEditableId(0)
    editForm.resetFields()
  }

  const fetchData = () => {
    attributeDictionaryValuesService.getById(+attributeDictionaryId).then((response) => {
      setDataSource(response.attributeDictionaryValues)
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Действия',
      key: 'actions',
      render: (_: any, record: AttributeDictionaryValue) => (
                <Space size="middle">
                    <a onClick={() => {
                      const { id, name } = record
                      editForm.setFieldValue('id', id)
                      editForm.setFieldValue('name', name)
                      setEditableId(id)
                    }}>Изменить</a>
                </Space>
      )
    }
  ]
  return (
        <div>
            <AdminPageTitle title={'Словари'}
                            buttonText={'Добавить'}
                            onClickButton={onShowCreateForm}/>

            <Table loading={loading} dataSource={dataSource} columns={columns} rowKey={'id'} />

            <Drawer
                title="Добавить"
                width={600}
                onClose={closeCreateForm}
                open={showCreateForm}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={closeCreateForm}>Отмена</Button>
                        <Button onClick={createForm.submit} type="primary">
                            Отправить
                        </Button>
                    </Space>
                }
            >
                <Form layout="vertical"
                      form={createForm}
                      onFinish={onSubmitCreateForm}>
                    <Form.Item label="Name"
                               name="name"
                               rules={[{ required: true }]}>
                        <Input/>
                    </Form.Item>
                </Form>
            </Drawer>

            <Drawer
                title="Редактирование"
                width={600}
                onClose={onCloseEditForm}
                open={!!editableId}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={onCloseEditForm}>Отмена</Button>
                        <Button onClick={editForm.submit} type="primary">
                            Отправить
                        </Button>
                    </Space>
                }
            >
                <Form layout="vertical"
                      form={editForm}
                      onFinish={onSubmitEditForm}>
                    <Form.Item label="Name"
                               name="name"
                               rules={[{ required: true }]}>
                        <Input/>
                    </Form.Item>
                </Form>
            </Drawer>
        </div>
  )
}

export default AdminAttributeDictionaryValuesPage
