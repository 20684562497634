import React from 'react'
import { Button, Form, Input, message, Typography } from 'antd'

import { useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'

const { Title, Text } = Typography

type LoginFormValues = {
    email: string;
    password: string;
}

const LoginForm: React.FC = () => {
  const navigate = useNavigate()
  const { login } = useAuth()
  const onFinish = (values: LoginFormValues) => {
    login(values.email, values.password)
      .catch((error) => {
        message.error(error.response.data.message)
      })
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
        <Form
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Title level={3}>Вход</Title>

            <Form.Item
                label="Эл. почта"
                name="email"
                rules={[
                  { required: true, message: 'Пожалуйста введите ваш Email!' },
                  { type: 'email', message: 'Email введен не верно' }
                ]}
            >
                <Input placeholder="Введите электронную почту"/>
            </Form.Item>

            <Form.Item
                label="Пароль"
                name="password"
                rules={[{ required: true, message: 'Пожалуйста введите ваш пароль!' }]}
            >
                <Input.Password/>
            </Form.Item>

            <Form.Item>
                <Button type="link" onClick={() => navigate('/restore-email')}>Забыли пароль?</Button>
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" size="large" block>
                    Войти
                </Button>
            </Form.Item>

            <Form.Item>
                <Text>Еще нет аккаунта?</Text>
                <Button type="link" onClick={() => navigate('/register')}>Регистрация</Button>
            </Form.Item>

        </Form>
  )
}

export default LoginForm
