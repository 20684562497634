import { noAuthAPI } from './apiService'
import tokenService from './tokenService'

export type RegisterFormValues = {
  email: string;
  password: string;
  confirm: string;
  firstName: string;
  lastName: string;
}

const authService = {
  register: (createUserData: RegisterFormValues) => noAuthAPI
    .post('auth/registration', {
      ...createUserData
    })
    .then((response) => {
      if (response.data.accessToken) {
        tokenService.set(response.data.accessToken)
      }
      return response.data
    }),
  login: (email: string, password: string) => noAuthAPI
    .post('auth/login', {
      email,
      password
    })
    .then((response) => {
      if (response.data.accessToken) {
        tokenService.set(response.data.accessToken)
      }
      return response.data
    }),
  logout: () => {
    tokenService.remove()
  },
  restoreLink: (email: string) => noAuthAPI
    .post('auth/get-restore-link', { email })
    .then((response) => {
      return response.data
    }),
  restorePassword: (email: string, hash: string, password: string) => noAuthAPI
    .post('auth/restore-password', {
      email,
      hash,
      password
    })
    .then((response) => {
      return response.data
    })

}
export default authService
