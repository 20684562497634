import React, { useEffect, useState } from 'react'
import { Table, Button, Form, Space, Drawer, message, Input } from 'antd'
import { Domain } from '../../services/models'
import AdminPageTitle from '../../components/AdminPageTitle/AdminPageTitle'
import domainsService from '../../services/domainsService'

type Props = {}

const AdminDomainsPage: React.FC<Props> = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [dataSource, setDataSource] = useState<Domain[]>([])

  const [createForm] = Form.useForm()
  const [showCreateForm, setShowCreateForm] = useState(false)

  const onShowCreateForm = () => {
    setShowCreateForm(true)
  }

  const closeCreateForm = () => {
    setShowCreateForm(false)
  }

  const onSubmitCreateForm = (data: { name:string }) => {
    setLoading(true)
    domainsService.create(data.name).then((data) => {
      message.success('Успех')
      fetchData()
    }).catch((data) => {
      message.error(data.message)
    }).finally(() => {
      setLoading(false)
    })
    setShowCreateForm(false)
    createForm.resetFields()
  }

  const [editForm] = Form.useForm()
  const [editableId, setEditableId] = useState<number>(0)

  const onCloseEditForm = () => {
    setEditableId(0)
    editForm.resetFields()
  }

  const onSubmitEditForm = (data: { name:string }) => {
    setLoading(true)
    domainsService.update(editableId, data.name).then((data) => {
      message.success('Успешно!')
      fetchData()
    }).catch((data) => {
      message.error(data.message)
    }).finally(() => {
      setLoading(false)
    })
    setEditableId(0)
    editForm.resetFields()
  }

  const fetchData = () => {
    domainsService.get()
      .then((response) => {
        setDataSource(response.domains)
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Действия',
      key: 'actions',
      render: (_: any, record: Domain) => (
                <Space size="middle">
                    <a onClick={() => {
                      const { id, name } = record
                      editForm.setFieldValue('id', id)
                      editForm.setFieldValue('name', name)
                      setEditableId(id)
                    }}>Изменить</a>
                </Space>
      )
    }
  ]
  return (
        <div>
            <AdminPageTitle title={'Домены'}
                            buttonText={'Добавить'}
                            onClickButton={onShowCreateForm}/>

            <Table loading={loading} dataSource={dataSource} columns={columns} rowKey={'id'} />

            <Drawer
                title="Добавить"
                width={600}
                onClose={closeCreateForm}
                open={showCreateForm}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={closeCreateForm}>Отмена</Button>
                        <Button onClick={createForm.submit} type="primary">
                            Отправить
                        </Button>
                    </Space>
                }
            >
                <Form layout="vertical"
                      form={createForm}
                      onFinish={onSubmitCreateForm}>
                    <Form.Item label="Name"
                               name="name"
                               rules={[{ required: true }]}>
                        <Input/>
                    </Form.Item>
                </Form>
            </Drawer>

            <Drawer
                title="Редактирование"
                width={600}
                onClose={onCloseEditForm}
                open={!!editableId}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={onCloseEditForm}>Отмена</Button>
                        <Button onClick={editForm.submit} type="primary">
                            Отправить
                        </Button>
                    </Space>
                }
            >
                <Form layout="vertical"
                      form={editForm}
                      onFinish={onSubmitEditForm}>
                    <Form.Item label="Name"
                               name="name"
                               rules={[{ required: true }]}>
                        <Input/>
                    </Form.Item>
                </Form>
            </Drawer>
        </div>
  )
}

export default AdminDomainsPage
