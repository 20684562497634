import React, { useEffect, useState } from 'react'
import { message } from 'antd'
import { Parser } from '../../services/models'
import userService from '../../services/userService'
import AdminPageTitle from '../../components/AdminPageTitle/AdminPageTitle'
import dayjs from 'dayjs'

const formatFilename = (f: string) => {
  const a = f.split('_')
  const t = a.shift() || ''
  const date = dayjs.unix(parseInt(t) / 1000).format('DD_MM_YYYY')
  return `${date}_${a.join('_')}`
}

type Props = {}

const AdminParserPage: React.FC<Props> = () => {
  const [parsers, setParsers] = useState<Parser[]>([])
  const fetchData = () => {
    userService.parsersList()
      .then((response) => {
        setParsers(response.list)
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const onDownload = (dir: string, file: string) => {
    const name = formatFilename(file)
    userService.parserDownloadFile(dir, file, name).catch(() => {
      message.error('Не удалось скачать')
    })
  }

  return (
        <div>
            <AdminPageTitle title={'Парсер'}/>
            {parsers.map((p) => (
                <div key={`dir-${p.dir}`}>
                    {p.dir}
                    <ul>{p.files.map((f) => (
                        <li key={`file-${f}`}><a onClick={() => onDownload(p.dir, f)}>{formatFilename(f)}</a></li>
                    ))}</ul>
                </div>
            ))}
        </div>
  )
}

export default AdminParserPage
