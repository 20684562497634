import React from 'react'
import ProfileInfoForm from '../../components/ProfileInfoForm/ProfileInfoForm'
import ProfilePasswordForm from '../../components/ProfilePasswordForm/ProfilePasswordForm'
import { Layout, Tabs, Typography } from 'antd'
import { Profile } from '../../services/models'
import ProfileAvatarForm from '../../components/ProfileAvatarForm/ProfileAvatarForm'
import SubscriptionForm from '../../components/SubscriptionForm/SubscriptionForm'
import { useAppSelector } from '../../app/hooks'
import { permItems } from '../../features/perm/permSlice'

type Props = {
    profile: Profile;
}

const ProfilePage: React.FC<Props> = ({ profile }) => {
  const permissions = useAppSelector(permItems)
  const tabs = [
    {
      label: 'Информация',
      key: '1',
      children: <ProfileInfoForm
                firstName={profile.firstName}
                lastName={profile.lastName}
                biography={profile.biography}
                job={profile.job}/>
    },
    {
      label: 'Фото',
      key: '2',
      children: <ProfileAvatarForm userId={profile.id}/>
    },
    {
      label: 'Пароль',
      key: '3',
      children: <ProfilePasswordForm/>
    },
    {
      label: 'Подписки',
      key: '4',
      children: <SubscriptionForm permissions={permissions} profile={profile}/>
    }
  ]

  return (
      <>
        <Layout>
          {/* <Breadcrumb items={getBreadcrumbItems()} style={{ margin: '10px' }}/> */}
          <Layout.Content style={{
            padding: 24,
            margin: '10px 10px 0 10px',
            minHeight: 280,
            background: 'white',
            maxWidth: '600px'
          }}>
            <Typography.Title>Профиль пользователя</Typography.Title>
            <Tabs
                defaultActiveKey="1"
                items={tabs}
            />
          </Layout.Content>
        </Layout>
      </>
  )
}

export default ProfilePage
