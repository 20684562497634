import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

interface OnChangeHandler {
  (e: any): void;
}
type WysiwygEditorProps = {
  value: string;
  placeholder: string;
  onChange: OnChangeHandler;
}
export const WysiwygEditor = (props: WysiwygEditorProps) => {
  const { value, onChange, placeholder } = props
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image'],
      ['clean']
    ]
  }

  const formats = [
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'color',
    'background',
    'script',
    'header',
    'blockquote',
    'code-block',
    'indent',
    'list',
    'direction',
    'align',
    'link',
    'image',
    'video',
    'formula'
  ]

  return <ReactQuill
    theme="snow"
    onChange={onChange}
    placeholder={placeholder}
    value={value || ''}
    modules={modules}
    formats={formats}
  />
}
