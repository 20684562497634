import React from 'react'
import { RootCategory } from '../../services/models'
import { Menu } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'

type Props = {
    category: RootCategory
}

const LeftMenuComponent: React.FC<Props> = ({ category }) => {
  const navigate = useNavigate()
  const { subCategoryId } = useParams()

  return (
        <Menu
            selectedKeys={[subCategoryId ?? '']}
            onClick={(e) => navigate(`/catalog/${category.id}/${e.key}`)}
            items={category.children.map((c) => ({ key: c.id.toString(), label: c.name }))}/>
  )
}

export default LeftMenuComponent
