import { RootState } from '../../app/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Profile } from '../../services/models'

export interface AuthState {
  loading: boolean;
  profile?: Profile;
}

const initialState: AuthState = {
  loading: true
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setProfile: (state, action: PayloadAction<Profile>) => {
      state.profile = action.payload
    }
  }
})

export const { setLoading, setProfile } = authSlice.actions

export const authLoading = (state: RootState) => state.auth.loading
export const authUser = (state: RootState) => state.auth.profile

export default authSlice.reducer
