import React, { useEffect, useState } from 'react'
import { Table, Space } from 'antd'
import adminService from '../../services/adminService'
import AdminPageTitle from '../../components/AdminPageTitle/AdminPageTitle'
import { useNavigate } from 'react-router-dom'

type Data = {
  key: string;
  id: number;
  title: string;
  user?: string;
  service?: string;
}

type Props = {}

const AdminCategoriesPage: React.FC<Props> = () => {
  const [loading, setLoading] = useState<boolean>(false)

  const [data, setData] = useState<Data[]>([])
  const fetchData = () => {
    adminService.allResearches().then((data) => {
      const newData: Data[] = []
      data.data.map((c) => {
        newData.push({
          key: c.id.toString(),
          id: c.id,
          title: c.title,
          user: c.user ? `${c.user.firstName} ${c.user.lastName}` : undefined,
          service: c.service ? c.service.name : undefined
        })
        return c
      })
      setData(newData)
      setLoading(false)
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const columns = [
    {
      title: 'Название',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Автор',
      dataIndex: 'user',
      key: 'user'
    },
    {
      title: 'Сервис',
      dataIndex: 'service',
      key: 'service'
    },
    {
      title: 'Действия',
      key: 'actions',
      render: (_: any, record: any) => (
          <Space size="middle">
            <a onClick={() => navigate('/researches/edit/' + record.id)}>Изменить</a>
            <a onClick={() => navigate('/researches/view/' + record.id)}>Просмотр</a>
          </Space>
      )
    }
  ]

  const navigate = useNavigate()

  return (
    <div>
      <AdminPageTitle title={'Управление исследованиями'}
                      buttonText={'Новое исследование'}
                      onClickButton={() => {
                        navigate('/researches/create')
                      }}/>
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
      />
    </div>
  )
}

export default AdminCategoriesPage
