import ImgCrop from 'antd-img-crop'
import React, { useState } from 'react'
import { Upload, message } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { API_URL } from '../../services/apiService'
import tokenService from '../../services/tokenService'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'
import type { UploadChangeParam } from 'antd/es/upload'

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader()
  // eslint-disable-next-line n/no-callback-literal
  reader.addEventListener('load', () => callback(reader.result as string))
  reader.readAsDataURL(img)
}

const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!')
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!')
  }
  return isJpgOrPng && isLt2M
}

type Props = {
    userId: number;
}

const ProfileAvatarForm: React.FC<Props> = ({ userId }) => {
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState<string>(`${API_URL}/avatar/${userId}`)

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false)
        setImageUrl(url)
      })
    }
  }

  const uploadButton = (
        <div>
            {loading ? <LoadingOutlined/> : <PlusOutlined/>}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
  )

  return (
      <ImgCrop>
          <Upload
              action={`${API_URL}/avatar/upload`}
              headers={
                  {
                    authorization: `Bearer ${tokenService.get()}`
                  }
              }
              listType="picture-card"
              maxCount={1}
              name="file"
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={handleChange}
          >
              {imageUrl ? <img src={imageUrl} alt="photo" style={{ width: '100%' }}/> : uploadButton}
          </Upload>
      </ImgCrop>
  )
}

export default ProfileAvatarForm
