import React from 'react'
import { Button, Form, Input, message } from 'antd'
import profileService from '../../services/profileService'

type FormValues = {
    firstName: string;
    lastName: string;
    job: string;
    biography: string;
}

type Props = {
    firstName: string;
    lastName: string;
    job: string;
    biography: string;
}

const ProfileInfoForm: React.FC<Props> = ({ firstName, lastName, job, biography }) => {
  const onFinish = ({ firstName, lastName, job, biography }: FormValues) => {
    profileService.updateInfo(firstName, lastName, job, biography).then(() => {
      message.success('update success')
    }).catch(() => {
      message.error('update error')
    })
  }

  return (
        <Form
            onFinish={onFinish}
            layout="vertical"
            initialValues={{
              firstName,
              lastName,
              job,
              biography
            }}>
            <Form.Item label="Имя" name="firstName" rules={[{ required: true }]}>
                <Input/>
            </Form.Item>
            <Form.Item label="Фамилия" name="lastName" rules={[{ required: true }]}>
                <Input/>
            </Form.Item>
            <Form.Item label="Должность" name="job">
                <Input/>
            </Form.Item>
            <Form.Item label="Биография" name="biography">
                <Input.TextArea rows={4}/>
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" type="primary">Изменить</Button>
            </Form.Item>
        </Form>
  )
}

export default ProfileInfoForm
