import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { RootCategory, SubCategory } from '../../services/models'
import { Layout } from 'antd'
import LeftMenuComponent from '../../components/LeftMenuComponent/LeftMenuComponent'
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent'

type Props = {
    categories: RootCategory[]
}

const CatalogLayout: React.FC<Props> = ({ categories }) => {
  const navigate = useNavigate()
  const { rootCategoryId, subCategoryId, serviceId } = useParams()
  const [isOver, setIsOver] = useState<boolean>(false)
  const [selectedRootCategory, setSelectedRootCategory] = useState<RootCategory>()

  useEffect(() => {
    if (categories.length === 0) return

    if (!rootCategoryId) {
      setSelectedRootCategory(undefined)
      if (categories.length && categories[0].children.length) {
        navigate(`/catalog/${categories[0].id}/${categories[0].children[0].id}`)
      }
      return
    }
    const rc = categories.find((c) => c.id.toString() === rootCategoryId)
    if (!rc) {
      setSelectedRootCategory(undefined)
      navigate('/')
      return
    }
    setSelectedRootCategory(rc)

    if (!subCategoryId) {
      return
    }
    const sc = rc.children.find((t) => t.id.toString() === subCategoryId)
    if (!sc) {
      navigate(`/catalog/${rootCategoryId}`)
      return
    }

    if (!serviceId) {
      return
    }
    const s = sc.services.find((s) => s.id.toString() === serviceId)
    if (!s) {
      navigate(`/catalog/${rootCategoryId}/${subCategoryId}`)
    }
  }, [categories, rootCategoryId, subCategoryId])

  return (
        <>
            <Layout.Content>
                <Layout style={{ minHeight: '500px' }}>
                    <Layout.Sider
                        theme="light"
                        width={isOver ? 250 : 200}
                        style={{
                          marginRight: isOver ? '-50px' : 0,
                          zIndex: 1
                        }}
                        onMouseLeave={() => setIsOver(false)}
                        onMouseEnter={() => setIsOver(true)}>
                        {selectedRootCategory && <LeftMenuComponent category={selectedRootCategory} />}
                    </Layout.Sider>
                    <Layout>
                        <BreadcrumbComponent categories={categories}/>
                        <div style={{ paddingLeft: '10px' }}>
                            <Outlet/>
                        </div>
                    </Layout>
                </Layout>
            </Layout.Content>
        </>
  )
}

export default CatalogLayout
