import { authAPI } from './apiService'
import { UploadFile } from 'antd'
import { Research, Service, User } from './models'

export type GetCategoriesResponse = {
  categories: {
    id: number;
    name: string;
    weight: number;
    isActive: boolean;
    parent: null | {
      id: number;
      name: string;
    };
  }[];
}

export type GetServicesResponse = {
  services: {
    id: number;
    name: string;
    weight: number;
    isActive: boolean;
    category: {
      id: number;
      name: string;
    };
  }[];
}

export type GetAdminResearchEditStoreResponse = {
  users: User[];
  services: {
    id: number;
    name: string;
    weight: number;
    isActive: boolean;
    category: {
      id: number;
      name: string;
    };
  }[];
}

export type GetAdminResearchesResponse = {
  data: {
    id: number
    title: string
    user: User
    service: Service
  }[]
}

export type GetResearchByIdResponse = {
  data: Research
}

export type UserListResponse = {
  users: User[]
}

export type UserGetByIdResponse = {
  id: number;
  email: string;
  role: number;
  serviceIds: number[];
}

const adminService = {
  getCategories: (): Promise<GetCategoriesResponse> => authAPI.get('/categories')
    .then((response) => response.data),

  createCategory: (name: string, weight: number, isActive: boolean, parentId?: number) => authAPI.post('/categories/create', { name, weight, isActive, parentId })
    .then((response) => response.data),

  updateCategory: (id: number, name: string, weight: number, isActive: boolean, parentId?: number) => authAPI.post('/categories/update', { id, name, weight, isActive, parentId })
    .then((response) => response.data),

  allResearches: (): Promise<GetAdminResearchesResponse> => authAPI.get('/researches/all')
    .then((response) => response.data),

  getResearchById: (id: number): Promise<GetResearchByIdResponse> => authAPI.get(`/researches/get/${id}`)
    .then((response) => response.data),

  removeFileById: (id: number): Promise<boolean> => authAPI.post(`/research-files/remove/${id}`)
    .then((response) => response.data),

  removeImageById: (id: number): Promise<boolean> => authAPI.post(`/research-images/remove/${id}`)
    .then((response) => response.data),

  removeResearch: (id: number): Promise<boolean> => authAPI.post(`/researches/remove/${id}`)
    .then((response) => response.data),

  createResearch: (title: string, body: string, serviceId: number, imageDescription: {[k: number]: string}, files?: UploadFile[], images?: UploadFile[]) => {
    const filesArray = files ? files.map((file) => file.response.id) : []
    const imagesArray = images ? images.map((image) => image.response.id) : []
    return authAPI.post('/researches/create', { title, body, serviceId, files: filesArray, images: imagesArray, imageDescription })
      .then((response) => response.data)
  },

  updateResearch: (id: number, title: string, body: string, serviceId: number, userId: number, imageDescription: {[k: number]: string}) => authAPI.post('/researches/update/' + id, { title, body, serviceId, userId, imageDescription })
    .then((response) => response.data),

  getServices: () => authAPI.get<GetServicesResponse>('/services')
    .then((response) => response.data),

  getAdminResearchEditStore: () => authAPI.get<GetAdminResearchEditStoreResponse>('/admin/research-edit-store')
    .then((response) => response.data),

  createService: (name: string, categoryId: number, weight: number, isActive: boolean) => authAPI.post('/services/create', { name, categoryId, weight, isActive })
    .then((response) => response.data),

  updateService: (id: number, name: string, categoryId: number, weight: number, isActive: boolean) => authAPI.post('/services/update', { id, name, categoryId, weight, isActive })
    .then((response) => response.data),

  userList: () => authAPI.get<UserListResponse>('/admin/user-list')
    .then((response) => response.data),

  userGetById: (userId: number) => authAPI.post<UserGetByIdResponse>('/admin/user-get-by-id', { userId })
    .then((response) => response.data),

  userSetRoleAndPermissions: (userId: number, role: number, serviceIds: number[]) => authAPI.post('/admin/user-set-role-and-permissions', { userId, role, serviceIds })
    .then((response) => response.data),

  closeAccess: (userId: number) => authAPI.post('/admin/set-guest-role', { userId })
    .then((response) => response.data),

  openAccess: (userId: number) => authAPI.post('/admin/set-user-role', { userId })
    .then((response) => response.data)
}

export default adminService
