import { authAPI } from './apiService'
import { Domain } from './models'

export type GetDomainsResponse = {
  domains: Domain[];
}

const URL = '/domains'

const domainsService = {

  get: () => authAPI.get<GetDomainsResponse>(URL)
    .then((response) => response.data),

  create: (name: string) => authAPI
    .post(`${URL}/create`, { name })
    .then((response) => response.data),

  update: (id: number, name: string) => authAPI
    .post(`${URL}/update`, { id, name })
    .then((response) => response.data)
}

export default domainsService
