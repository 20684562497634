import React, { useState } from 'react'
import {
  Button, Form, Input, message, Typography, Checkbox, Modal
} from 'antd'
import { useNavigate } from 'react-router-dom'
import { RegisterFormValues } from '../../services/authService'
import useAuth from '../../hooks/useAuth'
import Agreement from './Agreement'

const RegistrationForm: React.FC = () => {
  const navigate = useNavigate()
  const { register } = useAuth()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [agreement, setAgreement] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const onFinish = (values: RegisterFormValues) => register(values)
    .then(() => navigate('/'))
    .catch((error) => message.error(error.response.data.message))

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
        <Form
            name="basic"
            layout="vertical"
            labelAlign="left"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Typography.Title level={3}>Регистрация</Typography.Title>
            <Form.Item
                label="Имя"
                name="firstName"
                rules={[
                  { required: true, message: 'Пожалуйста введите Ваше имя!' }
                ]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                label="Фамилия"
                name="lastName"
                rules={[
                  { required: true, message: 'Пожалуйста введите Вашу фамилию!' }
                ]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: 'Пожалуйста введите ваш Email!' },
                  { type: 'email', message: 'Email введен не верно' }
                ]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                label="Пароль"
                name="password"
                rules={[{ required: true, message: 'Пожалуйста введите ваш пароль!' }]}
            >
                <Input.Password/>
            </Form.Item>

            <Form.Item
                name="confirm"
                label="Подтвердите пароль"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Пожалуйста введите ваш пароль!'
                  },
                  ({ getFieldValue }) => ({
                    validator (_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(new Error('Пароли должны совпадать'))
                    }
                  })
                ]}
            >
                <Input.Password/>
            </Form.Item>

            <Form.Item>
                <div style={{ display: 'flex' }}>
                    <Checkbox onChange={(e) => {
                      console.log(e.target.checked)
                      setAgreement(e.target.checked)
                    }}/>
                    <div style={{ paddingLeft: '10px' }}>Я принимаю условия <span
                        style={{ color: '#1890ff', cursor: 'pointer' }}
                        onClick={showModal}>пользовательского соглашения</span></div>
                </div>
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" size="large" block disabled={!agreement}>
                    Зарегистрироваться
                </Button>
            </Form.Item>

            <Form.Item>
                <Typography.Text>Еще уже есть аккаунта?</Typography.Text>
                <Button type="link" onClick={() => navigate('/')}>Авторизация</Button>
            </Form.Item>
            <Modal
                title="Политика обработки и защиты персональных данных"
                visible={isModalOpen}
                onCancel={handleCancel}
                width={1000}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Закрыть
                    </Button>
                ]}
            >
                <Agreement/>
            </Modal>
        </Form>
  )
}

export default RegistrationForm
