import { RootState } from '../../app/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Permission } from '../../services/models'

export interface AuthState {
  items: Permission[];
}

const initialState: AuthState = {
  items: []
}

export const permSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    setPermItems: (state, action: PayloadAction<Permission[]>) => {
      state.items = action.payload
    },
    subscribe: (state, action: PayloadAction<number>) => {
      state.items = state.items.map(p => {
        if (p.serviceId === action.payload) {
          p.isSubscribed = true
        }
        return p
      })
    },
    unsubscribe: (state, action: PayloadAction<number>) => {
      state.items = state.items.map(p => {
        if (p.serviceId === action.payload) {
          p.isSubscribed = false
        }
        return p
      })
    }
  }
})

export const { setPermItems, subscribe, unsubscribe } = permSlice.actions

export const permItems = (state: RootState) => state.perm.items

export default permSlice.reducer
