import React, { useEffect, useRef } from 'react'
import useAuth from '../../hooks/useAuth'
import UnauthorizedRouter from '../UnauthorizedRouter/UnauthorizedRouter'
import AuthorizedRouter from '../AuthorizedRouter/AuthorizedRouter'
import tokenService from '../../services/tokenService'
import { Spin } from 'antd'
import { setLoading } from '../../features/auth/authSlice'
import { useDispatch } from 'react-redux'

const Switcher = () => {
  const { profile, fetchProfile, loading } = useAuth()
  const calledOnce = useRef(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (calledOnce.current) return
    calledOnce.current = true
    const savedToken = tokenService.get()
    if (savedToken) {
      fetchProfile()
    } else {
      dispatch(setLoading(false))
    }
  })

  const renderRouter = () => {
    if (loading) return <div style={{ minHeight: '100vh' }}></div>
    return profile ? <AuthorizedRouter profile={profile}/> : <UnauthorizedRouter/>
  }

  return (
        <Spin spinning={loading}>{renderRouter()}</Spin>
  )
}

export default Switcher
