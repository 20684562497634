import React, { useState, useEffect } from 'react'
import userService from '../../services/userService'
import ResearchListComponent from '../../components/ResearchListComponent/ResearchListComponent'
import { Research } from '../../services/models'
import { Breadcrumb, Layout, Typography } from 'antd'

type Props = {}

const FeedPage: React.FC<Props> = () => {
  const [items, setItems] = useState<Research[]>([])

  useEffect(() => {
    userService.feed().then((data) => setItems(data.data))
  }, [])

  return (
        <>
            <Layout>
                {/* <Breadcrumb items={getBreadcrumbItems()} style={{ margin: '10px' }}/> */}
                <Layout.Content style={{
                  padding: 24,
                  margin: '10px 10px 0 10px',
                  minHeight: 280,
                  background: 'white'
                }}>
                    <Typography.Title>Лента</Typography.Title>
                    <ResearchListComponent items={items}/>
                </Layout.Content>
            </Layout>
        </>
  )
}

export default FeedPage
