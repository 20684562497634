import React, { useEffect, useState } from 'react'
import { List, Typography } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { RootCategory, SubCategory } from '../../services/models'

type Props = {
  categories: RootCategory[]
}

const CatalogPage: React.FC<Props> = ({ categories }) => {
  const navigate = useNavigate()
  const { rootCategoryId, subCategoryId } = useParams()
  const [selectedSubCategory, setSelectedSubCategory] = useState<SubCategory>()

  useEffect(() => {
    if (categories.length === 0) return

    if (!rootCategoryId) {
      setSelectedSubCategory(undefined)
      if (categories.length && categories[0].children.length) {
        navigate(`/catalog/${categories[0].id}/${categories[0].children[0].id}`)
      }
      return
    }
    const rc = categories.find((c) => c.id.toString() === rootCategoryId)
    if (!rc) {
      navigate('/')
      return
    }

    if (!subCategoryId) {
      setSelectedSubCategory(undefined)
      return
    }
    const sc = rc.children.find((t) => t.id.toString() === subCategoryId)
    if (!sc) {
      setSelectedSubCategory(undefined)
      navigate(`/catalog/${rootCategoryId}`)
      return
    }
    setSelectedSubCategory(sc)
  }, [categories, rootCategoryId, subCategoryId])

  if (!selectedSubCategory) {
    return (
        <Typography.Title level={3}>{'Выберите раздел'}</Typography.Title>
    )
  }
  return (
      <div style={{ backgroundColor: 'white', padding: '10px' }}>
        <Typography.Title level={3}>{selectedSubCategory.name}</Typography.Title>
        <List
            dataSource={selectedSubCategory?.services}
            renderItem={item => (
                <List.Item key={item.id}>
                  <List.Item.Meta
                      title={<a
                          onClick={() => navigate(`/catalog/${rootCategoryId}/${subCategoryId}/${item.id}`)}>{item.name}</a>}
                  />
                </List.Item>
            )}
        />
      </div>
  )
}

export default CatalogPage
