import { authAPI } from './apiService'
import { AttributeDictionaryValue } from './models'

export type GetAttributeDictionaryValuesResponse = {
    attributeDictionaryValues: AttributeDictionaryValue[];
}

const URL = '/attribute-dictionary-values'

const attributeDictionaryValuesService = {

  getById: (attributeDictionaryId: number) => authAPI
    .get<GetAttributeDictionaryValuesResponse>(`${URL}/${attributeDictionaryId}`)
    .then((response) => response.data),

  create: (attributeDictionaryId: number, name: string) => authAPI
    .post(`${URL}/create`, { attributeDictionaryId, name })
    .then((response) => response.data),

  update: (attributeDictionaryId: number, id: number, name: string) => authAPI
    .post(`${URL}/update`, { id, name, attributeDictionaryId })
    .then((response) => response.data)

}

export default attributeDictionaryValuesService
