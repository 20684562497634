import React, { useEffect, useState } from 'react'
import { Table, Tag, Button, Checkbox, Form, Space, Select, Drawer, message } from 'antd'
import adminService from '../../services/adminService'
import { RootCategory, User } from '../../services/models'
import userService from '../../services/userService'
import AdminPageTitle from '../../components/AdminPageTitle/AdminPageTitle'

const roles = [
  { label: 'UNKNOWN', value: 0 },
  { label: 'CLIENT', value: 1 },
  { label: 'ANALYST', value: 2 }
]

type Props = {}

const AdminUsersPage: React.FC<Props> = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [dataSource, setDataSource] = useState<User[]>([])
  const [rootCategories, setRootCategories] = useState<RootCategory[]>([])

  const [editForm] = Form.useForm()
  const [editableId, setEditableId] = useState<number>(0)

  const onCloseEditForm = () => {
    setEditableId(0)
    editForm.resetFields()
  }

  const onSubmitEditForm = (data: any) => {
    console.log('onSubmitEditForm', data)
    setLoading(true)
    adminService.userSetRoleAndPermissions(editableId, data.role, data.services).then((data) => {
      setLoading(false)
      message.success('Успешно!')
      fetchData()
    }).catch((data) => {
      setLoading(false)
      message.error(data.message)
    })
    setEditableId(0)
    editForm.resetFields()
  }

  const fetchData = () => {
    userService.getCategories()
      .then((response) => {
        setRootCategories(response.tree)
      })

    adminService.userList().then((data) => {
      const newDataSource: User[] = []
      data.users.map((user: any) => {
        newDataSource.push({
          key: user.id.toString(),
          ...user
        })
        return user
      })
      setDataSource(newDataSource)
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const roleTag = (role: number): React.ReactNode => {
    switch (role) {
      case 0:
        return <Tag color={'yellow'}>UNKNOWN</Tag>
      case 1:
        return <Tag color={'green'}>CLIENT</Tag>
      case 2:
        return <Tag color={'blue'}>ANALYST</Tag>
      case 9:
        return <Tag color={'red'}>ADMIN</Tag>
      default:
        return <></>
    }
  }

  const columns = [
    {
      title: 'Имя',
      dataIndex: 'firstName',
      key: 'firstName'
    },
    {
      title: 'Фамилия',
      dataIndex: 'lastName',
      key: 'lastName'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      width: '100px',
      title: 'Роль',
      dataIndex: 'role',
      key: 'role',
      render: (_: any, { role }: any) => roleTag(role)
    },
    {
      title: 'Действия',
      key: 'actions',
      render: (_: any, record: any) => (
                <Space size="middle">
                    <a onClick={() => {
                      const { id } = record
                      setLoading(true)
                      adminService.userGetById(id).then((data) => {
                        editForm.setFieldValue('role', data.role)
                        editForm.setFieldValue('services', data.serviceIds)
                        setEditableId(id)
                        setLoading(false)
                      })
                    }}>Изменить</a>
                </Space>
      )
    }
  ]
  return (
        <div>
            <AdminPageTitle title={'Управление пользователями'}/>
            <Table loading={loading} dataSource={dataSource} columns={columns}/>
            <Drawer
                title="Редактирование"
                width={600}
                onClose={onCloseEditForm}
                open={!!editableId}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={onCloseEditForm}>Отмена</Button>
                        <Button onClick={editForm.submit} type="primary">
                            Отправить
                        </Button>
                    </Space>
                }
            >
                <Form layout="vertical"
                      form={editForm}
                      onFinish={onSubmitEditForm}>
                    <Form.Item label="Роль" name="role">
                        <Select options={roles}/>
                    </Form.Item>
                    <Form.Item name="services" label="Доступные сервисы">
                        <Checkbox.Group>
                            <ul>
                                {rootCategories.map((rootCategory) => <li key={`c-${rootCategory.id}`}>
                                    {rootCategory.name}
                                    <ul>
                                        {rootCategory.children.map((category) => <li key={`c-${category.id}`}>
                                            {category.name}
                                            <ul>
                                                {category.services.map((service) => <li key={`s-${service.id}`}>
                                                    <Checkbox value={service.id}>{service.name}</Checkbox>
                                                </li>)}
                                            </ul>
                                        </li>)}
                                    </ul>
                                </li>)}
                            </ul>
                        </Checkbox.Group>
                    </Form.Item>
                </Form>
            </Drawer>
        </div>
  )
}

export default AdminUsersPage
