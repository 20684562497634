import React, { useEffect, useState } from 'react'
import {
  Button,
  Form,
  Input,
  Select,
  Space,
  Upload,
  UploadFile,
  UploadProps
} from 'antd'
import adminService from '../../services/adminService'
import { WysiwygEditor } from '../Editor/WysiwygEditor'
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons'
import { API_URL } from '../../services/apiService'
import tokenService from '../../services/tokenService'
import { Research } from '../../services/models'

type ServiceOption = {
    key: string;
    id: number;
    name: string;
    categoryId: number
    categoryName: string;
}

type UserOption = {
    key: string;
    id: number;
    name: string;
}

export type UploadFilesForm = {
    file: any,
    fileList: UploadFile[]
}

export type UploadImagesForm = {
    file: any,
    fileList: UploadFile[]
}

export type ResearchCreateFields = {
    serviceId: number,
    userId: number,
    title: string,
    body: string,
    files?: UploadFilesForm
    images?: UploadImagesForm
}

export type Props = {
    data?: Research;
    onDelete?: () => void,
    onSubmit: (data: ResearchCreateFields, imageDescription: { [k: number]: string }) => void,
}

export const ResearchForm: React.FC<Props> = ({ data, onSubmit, onDelete }) => {
  const [users, setUsers] = useState<UserOption[]>([])
  const [services, setServices] = useState<ServiceOption[]>([])
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [imageList, setImageList] = useState<UploadFile[]>([])
  const [imageDescription, setImageDescription] = useState<{ [k: number]: string }>({})
  const [isEdit, setIsEdit] = useState(!!data)
  const [form] = Form.useForm()

  const fetchData = () => {
    adminService.getAdminResearchEditStore().then((data) => {
      const serviceOptions: ServiceOption[] = []
      data.services.forEach((s) => {
        serviceOptions.push({
          key: s.id.toString(),
          id: s.id,
          name: s.name,
          categoryId: s.category.id,
          categoryName: s.category.name
        })
      })
      setServices(serviceOptions)

      const userOptions: UserOption[] = []
      data.users.forEach((u) => {
        userOptions.push({
          id: u.id, key: u.id.toString(), name: `${u.firstName} ${u.lastName} (${u.email})`
        })
      })
      setUsers(userOptions)
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    setIsEdit(!!data)
  }, [data])

  const handleFileUploadChange: UploadProps['onChange'] = (info) => {
    let newList = [...info.fileList]

    newList = newList.map((file) => {
      if (file.response) {
        file.url = file.response.url
      }
      return file
    })

    setFileList(newList)
  }

  const handleImageUploadChange: UploadProps['onChange'] = (info) => {
    let newList = [...info.fileList]

    newList = newList.map((file) => {
      // if (file.response) {
      //   file.url = file.response.url
      // }
      return file
    })

    setImageList(newList)
  }

  useEffect(() => {
    if (isEdit) {
      form.setFieldValue('title', data?.title)
      form.setFieldValue('userId', data?.user.id.toString())
      form.setFieldValue('serviceId', data?.service.id.toString())
      form.setFieldValue('body', data?.body)
      if (data?.imageDescription) {
        setImageDescription(data?.imageDescription)
      }
      if (data?.files) {
        const newFileList: UploadFile[] = []
        data.files.forEach((file) => {
          newFileList.push({
            uid: file.id.toString(),
            name: file.name,
            status: 'done',
            // url: file.url,
            type: file.mime,
            response: {
              id: file.id
            }
          })
        })
        setFileList(newFileList)
      }
      if (data?.images) {
        const newImageList: UploadFile[] = []
        data.images.forEach((image) => {
          newImageList.push({
            uid: image.id.toString(),
            name: image.name,
            status: 'done',
            url: `${API_URL}/${image.path}`,
            type: image.mime,
            response: {
              id: image.id
            }
          })
        })
        setImageList(newImageList)
      }
    }
  }, [isEdit])

  return (
        <Form
            form={form}
            layout="vertical"
            style={{ width: '100%' }}
            onFinish={(data) => {
              onSubmit(data, imageDescription)
            }}>
            <Form.Item
                label="Заголовок"
                name="title"
                rules={[
                  {
                    required: true,
                    message: 'Пожалуйста заполните заголовок'
                  }
                ]}>
                <Input/>
            </Form.Item>
            <Form.Item
                label="Service"
                name="serviceId"
            >
                <Select>
                    {services.map(option => (
                        <Select.Option key={option.key}>{`${option.categoryName} –> ${option.name}`}</Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                label="User"
                name="userId"
            >
                <Select>
                    {users.map(option => (
                        <Select.Option key={option.key}>{option.name}</Select.Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item
                name="body"
                label="Основной текст"
                rules={[
                  {
                    required: true,
                    message: 'Пожалуйста заполните основной текст'
                  }
                ]}
            >
                {/* @ts-ignore */}
                <WysiwygEditor value={data?.body || ''}/>
            </Form.Item>
            <Form.Item name="files" label="Документы">
                <Upload
                    action={`${API_URL}/research-files/upload` + (data?.id ? `?researchId=${data.id}` : '')}
                    headers={{ authorization: `Bearer ${tokenService.get()}` }}
                    name="file"
                    multiple={true}
                    listType='text'
                    fileList={fileList}
                    onChange={handleFileUploadChange}
                    onRemove={(file: UploadFile) => {
                      if (file?.response?.id) {
                        return adminService.removeFileById(file.response.id)
                      }
                      return true
                    }}
                >
                    <Button icon={<UploadOutlined/>}>Загрузить файлы</Button>
                </Upload>
            </Form.Item>
            <Form.Item name="images" label="Галерея">
                <Upload
                    action={`${API_URL}/research-images/upload` + (data?.id ? `?researchId=${data.id}` : '')}
                    headers={{ authorization: `Bearer ${tokenService.get()}` }}
                    name="file"
                    multiple={true}
                    listType='picture'
                    fileList={imageList}
                    onChange={handleImageUploadChange}
                    itemRender={(originNode, file, fileList, actions) => {
                      return (
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <img style={{ padding: '4px' }}
                                         src={file.thumbUrl ?? file.url}
                                         height={80}
                                         width={80}/>
                                </div>
                                <div>
                                    <Input
                                        placeholder="Описание"
                                        onChange={(e) => {
                                          let id = 0
                                          if (file?.response?.id) {
                                            id = file.response.id
                                          }
                                          setImageDescription(old => ({
                                            ...old,
                                            [id]: e.target.value
                                          }))
                                        }}
                                        defaultValue={imageDescription[parseInt(file.uid)]}
                                    />
                                </div>
                                <div>
                                    <Button icon={<DeleteOutlined/>} onClick={actions.remove}/>
                                </div>
                            </div>

                      )
                    }}
                    onRemove={(file: UploadFile) => {
                      if (file?.response?.id) {
                        return adminService.removeImageById(file.response.id)
                      }
                      return true
                    }}
                >
                    <Button icon={<UploadOutlined/>}>Загрузить файлы</Button>
                </Upload>
            </Form.Item>
            <Space>
                {onDelete && (
                    <Button
                        type="default"
                        onClick={onDelete}
                        danger
                    >
                        Удалить
                    </Button>
                )}

                <Button
                    type="primary"
                    onClick={form.submit}
                >
                    Сохранить
                </Button>

            </Space>
        </Form>
  )
}
