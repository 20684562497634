import { authAPI } from './apiService'
import { Permission, Profile } from './models'

type ProfileResponse = {
  data: Profile
}

type PermissionsResponse = {
  data: Permission[]
}

const profileService = {
  profile: () => authAPI.get<ProfileResponse>('/profile')
    .then((response) => response.data),

  permissions: () => authAPI.get<PermissionsResponse>('/profile/permissions')
    .then((response) => response.data),

  updateInfo: (firstName: string, lastName: string, job: string, biography: string) => authAPI.post('/profile/update-info', { firstName, lastName, job, biography })
    .then((response) => response.data),

  updatePassword: (oldPassword: string, newPassword: string) => authAPI.post('/profile/update-password', { oldPassword, newPassword })
    .then((response) => response.data),

  updateSchedule: (schedule: number) => authAPI.post('/profile/update-schedule', { schedule })
    .then((response) => response.data)
}

export default profileService
